import React, { useEffect } from 'react';
import { Avatar, Button } from '@kaya/kaya-ui-design-system-pb';
import { truncateWithEllipsis } from '../../../utils';
import { useMobile } from '../../../hooks';
import { toast } from 'react-toastify';

export enum IUploadType {
    Spreadsheet = 'Spreadsheet',
    CSV = 'CSV',
    Document = 'Document',
    PDF = 'PDF',
    Text = 'Text',
}

interface IUploadCardType {
    file: File;
    hasRemove: boolean;
    handleRemove?: () => void;
}

interface IUploadInputType {
    label: string;
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    acceptedFiles: string;
}

interface IUploadButton {
    label: string;
    loading: boolean;
    disabled: boolean;
    onClick: () => void;
}

const getFileUploadType = (file: File) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const fileTypes: { [key: string]: IUploadType } = {
        pdf: IUploadType.PDF,
        docx: IUploadType.Document,
        txt: IUploadType.Text,
        xlsx: IUploadType.Spreadsheet,
        csv: IUploadType.CSV,
    };

    return fileTypes[fileExtension ?? ''] || IUploadType.Document;
};

export const UploadInput = ({ label, acceptedFiles, handleFileChange }: IUploadInputType) => {
    const isMobile = useMobile();

    useEffect(() => {
        if (isMobile) {
            const input = document.getElementById('uploadInput-id');

            const handleFileTypes = (event: any) => {
                const files = Array.from(event.target.files);
                const invalidFiles = files.filter((file: any) => !acceptedFiles.includes(file.type));

                if (invalidFiles.length > 0) {
                    toast.warning('Invalid file type. Please select a valid file.');
                    event.target.value = '';
                } else {
                    handleFileChange(event);
                }
            };

            input?.addEventListener('change', handleFileTypes);
            return () => {
                input?.removeEventListener('change', handleFileTypes);
            };
        }
    }, [handleFileChange]);

    return (
        <label className="flex flex-col items-center text-B-600 cursor-pointer p-2 border w-full rounded-md hover:bg-B-400 hover:text-white ease-in-out duration-300">
            <span>
                <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
            </span>
            <span className="text-md">{label}</span>
            <input
                type="file"
                id="uploadInput-id"
                className="hidden"
                multiple
                accept={acceptedFiles}
                {...(isMobile ? { onChange: undefined } : { onChange: handleFileChange })}
            />
        </label>
    );
};

export const UploadButton = ({ label, loading, disabled, onClick }: IUploadButton) => {
    return (
        <Button
            isDisabled={disabled}
            isLoading={loading}
            className="bg-B-700 hover:bg-B-800 text-white text-md outline-none border-none font-bold py-2 px-4 my-1 rounded-md w-full flex items-center disabled:bg-N-400 disabled:cursor-not-allowed"
            onClick={onClick}
            iconBefore={loading ? '' : 'ri-chat-check-line'}
        >
            {label}
        </Button>
    );
};

export const UploadCard = ({ file, hasRemove, handleRemove }: IUploadCardType) => {
    return (
        <div className="flex justify-between items-center border border-N-200 pr-2 pl-4 py-3 rounded-md min-w-[260px] max-w-full bg-white drop-shadow-sm">
            <div className="flex items-center gap-x-3">
                <Avatar
                    type="image"
                    shape="square"
                    size="md"
                    imageURL="https://img.icons8.com/fluency/48/document.png"
                />
                <div className="flex flex-col items-start gap-y-[2px]">
                    <p
                        className="text-sm font-500 text-N-900 break-words truncate max-w-[300px] sm:max-w-[150px]"
                        title={file.name}
                    >
                        {truncateWithEllipsis(file.name, 25)}
                    </p>
                    <p className="text-sm font-400 text-N-600">{getFileUploadType(file)}</p>
                </div>
            </div>
            {hasRemove && (
                <i
                    className="ri-delete-bin-6-line mx-1 cursor-pointer hover:font-bold"
                    onClick={() => handleRemove?.()}
                />
            )}
        </div>
    );
};
