import { useEffect, useState } from 'react';
import { useChatContext } from '../../../contexts';
import { useMessageContent } from '../../../hooks';
import { ButtonWithMessage, ChatMessage, CommonMessage, FormattedChatMessage, ScrollableWrapper } from '../../common';
import { Accordion, Button } from '@kaya/kaya-ui-design-system-pb';
import { LEAD_SUGGESTIONS, SALES_REP_INFO } from '../../../constants';
import SalesRepTable from './common/SalesRepTable';
import SalesRepActionTable from './common/SalesRepActionTable';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

const refPathUrl =
    'https://storage.googleapis.com/kaya-bernie-public-bucket/kpl/HubSpot%20Assign%20App%20Overview%20%E2%80%93%20Insycle.png';

const LeadSyncKPL = () => {
    const { containerRef } = useMessageContent();
    const { userFirstName } = useChatContext();

    const [isProceedBtnLoading, setIsProceedBtnLoading] = useState(false);
    const [isDownloadBtnLoading, setIsDownloadBtnLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [assignedSalesRep, setAssignedSalesRep] = useState(LEAD_SUGGESTIONS);
    const [isPushHubSpotBtnLoading, setIsPushHubSpotBtnLoading] = useState<boolean>(false);

    useEffect(() => {
        if (isProceedBtnLoading) {
            const timer = setTimeout(() => {
                setIsProceedBtnLoading(false);
                setStep(1);
            }, 2500);

            return () => clearTimeout(timer);
        }
    }, [isProceedBtnLoading]);

    useEffect(() => {
        if (isDownloadBtnLoading) {
            const timer = setTimeout(() => {
                setIsDownloadBtnLoading(false);
                setStep(3);
            }, 2500);

            return () => clearTimeout(timer);
        }
    }, [isDownloadBtnLoading]);

    const filterAndDownloadExcel = (data: any) => {
        setTimeout(() => {
            const filteredData = data.filter((item: any) => item.isAccept === 1);
            const dataWithoutIsAccept = filteredData.map(({ isAccept, ...rest }: any) => rest);

            try {
                const worksheet = XLSX.utils.json_to_sheet(dataWithoutIsAccept);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Leads Data');
                XLSX.writeFile(workbook, 'Leads_Assigned.xlsx');
            } catch (error) {
                console.log('Error generating Excel file:', error);
            }
        }, 1500);
    };

    const handleReferenceView = (refUrl: string) => {
        const url = `${refUrl}`;
        window.open(url, '_blank');
    };

    const handlePushIntoHubSpot = () => {
        toast.success('Successfully updated in HubSpot', {
            autoClose: 1500,
        });
        setIsPushHubSpotBtnLoading(true);
        setTimeout(() => {
            setIsPushHubSpotBtnLoading(false);
        }, 1500);
    };

    return (
        <ScrollableWrapper ref={containerRef}>
            <ChatMessage
                disableCopy
                text={`Hi, ${userFirstName} 👋
            \nWelcome to LeadSync, your AI-powered lead assignment assistant!
            \n- This tool is designed to simplify the process of assigning leads to your sales team by analyzing key metrics like geography, industry, and workload.
            \nLet’s get started by viewing the suggested assignments tailored for your team.`}
            />

            <ButtonWithMessage
                displayCondition={true}
                disabledCondition={step != 0}
                buttonText="Get Started"
                disabledText={isProceedBtnLoading ? 'Analyzing...' : 'Analysis complete'}
                isLoading={isProceedBtnLoading}
                onClick={() => {
                    setIsProceedBtnLoading(true);
                }}
                icon="ri-sparkling-line"
                text="👇 Please click on the button below to generate requirement"
            />

            {step >= 1 && (
                <CommonMessage
                    isUser={false}
                    disableCopy
                    text="Select"
                    content={
                        <>
                            <FormattedChatMessage
                                text={`LeadSync will guide you through a streamlined process to ensure each lead is matched with the right sales rep:
                                    \n- View Sales Rep Details – Get a snapshot of each rep's current workload, live deals, and active leads.
                                    \n- Suggested Assignments – Review AI-powered lead assignment suggestions based on factors like geography, industry, and current capacity.
                                    \n- Accept or Edit – Choose to accept the suggestions or make adjustments for a better fit.
                                    \n- Finalize & Sync – Once assigned, LeadSync will seamlessly sync the updates to HubSpot and notify the team via Slack.
                                    \nLet’s dive in and start with the Sales Rep overview!`}
                            />

                            <div className="reference w-full flex flex-col border border-N-200">
                                <Accordion
                                    items={[
                                        {
                                            title: 'Sales Rep Information Panel',
                                            content: (
                                                <div className="px-6 py-5">
                                                    <FormattedChatMessage
                                                        text={`Here, you’ll find all your team members along with key stats on their workload. The panel displays each sales rep’s name, location, number of assigned leads, and current live deals. This helps you make informed decisions by understanding each rep's capacity at a glance.`}
                                                    />
                                                    <div className="my-3 overflow-x-scroll">
                                                        <SalesRepTable tableData={SALES_REP_INFO} />
                                                    </div>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Suggested Lead Assignment Panel',
                                            content: (
                                                <div className="px-6 py-5">
                                                    <FormattedChatMessage
                                                        text={`LeadSync has automatically analyzed incoming leads and provided recommendations on the best sales rep for each one. This panel displays key information for each lead, including the suggested rep, location, and potential revenue. You have the option to accept the suggested assignment or make adjustments if needed.
                                                            \n 👍 Accept & Assign When you’re satisfied with the suggested assignments, simply click Accept & Assign. LeadSync will immediately assign the lead to the selected sales rep and update HubSpot for real-time tracking. This action also sends a notification to your Sales Ops Slack channel to keep the team in sync.
                                                            \n ✏️ Edit Not the right fit? You can make adjustments to any suggested lead assignment by clicking Edit. Select a different sales rep based on your expertise or specific requirements. After editing, you can proceed to finalize the assignment as usual.
                                                            \n 📝 Add Any Comments/Notes If you need to provide context or add any specific instructions related to the lead assignment, use the text box below to enter your comments. These notes can serve as a record for why certain decisions were made and can help ensure smooth follow-up actions.`}
                                                    />
                                                    <div className="my-4 overflow-x-scroll">
                                                        <SalesRepActionTable
                                                            data={assignedSalesRep}
                                                            setAssignedSalesRep={setAssignedSalesRep}
                                                            step={step}
                                                        />
                                                    </div>
                                                </div>
                                            ),
                                        },
                                    ]}
                                />
                                <Button
                                    onClick={() => {
                                        setStep(2);
                                    }}
                                    isDisabled={step >= 2}
                                    size="medium"
                                    view="ghost"
                                    iconAfter="ri-arrow-right-double-fill"
                                    className="bg-B-500 p-2 m-5 hover:bg-B-400 text-white mt-4 self-start"
                                >
                                    Proceed
                                </Button>
                            </div>
                        </>
                    }
                />
            )}

            {step >= 2 && (
                <>
                    <CommonMessage
                        isUser={false}
                        disableCopy
                        text="Select"
                        content={
                            <>
                                <FormattedChatMessage
                                    text={`🎉 All Done!
                                    \n You've successfully assigned leads using LeadSync! With AI-driven suggestions and seamless 
                                    HubSpot integration, we hope this tool saves you time and keeps your team organized. 
                                    \n To push these into HubSpot, please click the button below.
                                    `}
                                />

                                <Button
                                    className="bg-A-500 hover:bg-A-400 text-white outline-none border-none py-2 px-4 rounded-md w-50 flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                                    iconBefore="ri-upload-cloud-fill"
                                    view="outline"
                                    isLoading={isPushHubSpotBtnLoading}
                                    onClick={() => {
                                        handlePushIntoHubSpot();
                                    }}
                                >
                                    Push into HubSpot
                                </Button>

                                <p>Let's continue making lead management smarter and easier!</p>
                            </>
                        }
                    />

                    <CommonMessage
                        isUser={false}
                        disableCopy
                        text="Select"
                        content={
                            <>
                                <div>
                                    <p className="mb-6 text-lg font-extra-bold text-black flex items-center">
                                        <span role="img" aria-label="books">
                                            📚
                                        </span>{' '}
                                        References
                                    </p>

                                    <div className="ms-10">
                                        <span
                                            onClick={() => {
                                                handleReferenceView(refPathUrl);
                                            }}
                                            className="cursor-pointer text-B-600 font-medium underline decoration-solid hover:text-blue-800"
                                        >
                                            View in HubSpot
                                        </span>
                                    </div>
                                </div>
                            </>
                        }
                    />

                    <ButtonWithMessage
                        displayCondition={true}
                        disabledCondition={step >= 3}
                        buttonText="Download"
                        disabledText={isDownloadBtnLoading ? 'Download...' : 'Download completed'}
                        isLoading={isDownloadBtnLoading}
                        onClick={() => {
                            setIsDownloadBtnLoading(true);
                            filterAndDownloadExcel(assignedSalesRep);
                        }}
                        icon="ri-sparkling-line"
                        text="👇 Please click on the button below to download excel file"
                    />
                </>
            )}
        </ScrollableWrapper>
    );
};

export { LeadSyncKPL };
