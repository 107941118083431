import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import { KeycloakService } from './services';
import mermaid from 'mermaid';

const renderApp = () =>
    ReactDOM.createRoot(document.getElementById('root')!).render(
        // Commented out React.StrictMode due to unnecessary side effects happening in the deployed environment.
        // In development (e.g., in your local environment), uncomment the code for better detection of bugs.
        // <React.StrictMode>
        <App />
        // </React.StrictMode>
    );

KeycloakService.initKeycloak(renderApp);

mermaid.initialize({
    startOnLoad: true,
    theme: 'default',
});
