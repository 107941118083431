import React, { useEffect, useRef, useState } from 'react';
import mermaid from 'mermaid';
import { MermaidConfig } from 'mermaid/dist/config.type';
import { Button } from '@kaya/kaya-ui-design-system-pb';

const Mermaid = ({ chart }: any) => {
    const mermaidRef = useRef<HTMLDivElement>(null);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const renderMermaid = async () => {
            try {
                const config: MermaidConfig = {};

                mermaid.contentLoaded();
                mermaid.initialize(config);
                await mermaid.run();
                setError(false);
            } catch (error) {
                setError(true);
            }
        };
        if (mermaidRef.current) {
            renderMermaid().then(() => console.debug('graph rendered'));
        }
    }, [chart]);

    const handleViewInNewTab = () => {
        if (mermaidRef.current) {
            const svgElement = mermaidRef.current.querySelector('svg');
            if (svgElement) {
                const svgData = new XMLSerializer().serializeToString(svgElement);
                const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
                const url = URL.createObjectURL(svgBlob);
                window.open(url, '_blank');
            }
        }
    };

    const handleDownload = () => {
        if (mermaidRef.current) {
            const svgElement = mermaidRef.current.querySelector('svg');
            if (svgElement) {
                const svgData = new XMLSerializer().serializeToString(svgElement);
                const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
                const url = URL.createObjectURL(svgBlob);
                const downloadLink = document.createElement('a');

                downloadLink.href = url;
                downloadLink.download = `${svgElement?.id}.svg`;
                downloadLink.click();
                URL.revokeObjectURL(url);
            }
        }
    };

    return (
        <>
            {error ? (
                <pre>Couldn't generate chart due to an error in the original response. Please try again later.</pre>
            ) : (
                <div className="mr-1">
                    <div ref={mermaidRef} className="mermaid p-1">
                        {chart}
                    </div>
                    <div className="flex gap-2 mt-2">
                        <div className="w-full flex justify-end mt-[8px] gap-x-2">
                            <Button
                                className="font-[inter]"
                                onClick={handleViewInNewTab}
                                appearance="primary"
                                type="button"
                                view="outline"
                                size="small"
                            >
                                View
                            </Button>
                            <Button
                                className="font-[inter]"
                                onClick={handleDownload}
                                appearance="primary"
                                type="button"
                                view="outline"
                                size="small"
                            >
                                Download
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Mermaid;
