import { useMemo, useState } from 'react';
import { UITableV2 } from '../../../common/table';
import { Button, Tooltip, UIModalV2 } from '@kaya/kaya-ui-design-system-pb';
import { uniqueId } from '../../../../utils';
import { SALES_REP_INFO } from '../../../../constants';

const SalesRepActionTable = ({ data, setAssignedSalesRep, step }: any) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [tableData, setTableData] = useState(data);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [selectedSalesRep, setSelectedSalesRep] = useState('');
    const [comment, setComment] = useState('');

    const openModal = (row: any) => {
        setSelectedRow(row);
        setSelectedSalesRep(row.suggestedSalesRep);
        setComment(row.reasonOfSuggestion);
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    const handleSelectChange = (event: any) => {
        setSelectedSalesRep(event.target.value);
    };

    const handleCommentChange = (event: any) => {
        setComment(event.target.value);
    };

    const saveChanges = () => {
        const updatedTableData = tableData.map((item: any) =>
            item.leadId === selectedRow.leadId
                ? {
                      ...item,
                      suggestedSalesRep: selectedSalesRep,
                      reasonOfSuggestion: comment,
                      status: 'Assigned & Accept',
                      isAccept: 1,
                  }
                : item
        );
        setTableData(updatedTableData);
        setAssignedSalesRep(updatedTableData);
        closeModal();
    };

    const acceptAndAssign = (rowId: any) => {
        setTableData((prevTableData: any) => {
            const updatedTableData = prevTableData.map((item: any) =>
                item.leadId === rowId ? { ...item, status: 'Accepted', isAccept: 1 } : item
            );
            return updatedTableData;
        });

        setAssignedSalesRep((prevTableData: any) => {
            const updatedTableData = prevTableData.map((item: any) =>
                item.leadId === rowId ? { ...item, status: 'Accepted', isAccept: 1 } : item
            );
            return updatedTableData;
        });
    };

    const tableColumnDefinition = useMemo(
        () => [
            {
                id: '1',
                Header: 'Lead ID',
                showHeader: true,
                accessor: 'leadId',
                isSortable: true,
                width: 50,
                minWidth: 50,
                isCustomizable: false,
                Cell: ({ row }: any) => <p className="text-N-900 font-500">{row?.original?.leadId}</p>,
            },
            {
                id: '2',
                Header: 'Lead Location',
                showHeader: true,
                accessor: 'leadLocation',
                isSortable: true,
                width: 80,
                minWidth: 80,
                isCustomizable: false,
                Cell: ({ row }: any) => <p className="text-N-900 font-500">{row?.original?.leadLocation}</p>,
            },
            {
                id: '3',
                Header: 'Read Revenue',
                showHeader: true,
                accessor: 'readRevenue',
                isSortable: true,
                width: 50,
                minWidth: 50,
                isCustomizable: false,
                Cell: ({ row }: any) => <p className="text-N-900 font-500">{row?.original?.readRevenue}</p>,
            },
            {
                id: '4',
                Header: 'Suggested Sales Rep',
                showHeader: true,
                accessor: 'suggestedSalesRep',
                isSortable: true,
                width: 150,
                minWidth: 150,
                isCustomizable: false,
                Cell: ({ row }: any) => (
                    <div className="flex items-center">
                        <p className="text-N-600 max-w-[200px] truncate">{row?.original?.suggestedSalesRep}</p>
                        <Tooltip
                            id={uniqueId()}
                            title={row?.original?.reasonOfSuggestion}
                            tooltipType="minimal"
                            variant="dark"
                            place="top"
                        >
                            <span className="ms-5">
                                <i className="ri-information-fill"></i>
                            </span>
                        </Tooltip>
                    </div>
                ),
            },
            {
                id: '6',
                Header: 'Status',
                showHeader: true,
                accessor: 'status',
                isSortable: true,
                width: 50,
                minWidth: 50,
                isCustomizable: false,
                Cell: ({ row }: any) => <p className="text-N-900 font-500">{row?.original?.status}</p>,
            },
            {
                id: '7',
                Header: 'Action',
                showHeader: true,
                accessor: 'action',
                isSortable: true,
                width: 30,
                minWidth: 30,
                isCustomizable: false,
                Cell: ({ row }: any) => (
                    <div className="flex items-center justify-around gap-x-3">
                        <div>
                            <Tooltip
                                id={uniqueId()}
                                place="top"
                                variant="dark"
                                tooltipType="detailed"
                                title={`${row.original.status === 'Assigned' ? 'Assigned' : 'Accept & Assign'}`}
                            >
                                <Button
                                    className="text-B-500 py-1 px-2 hover:text-B-400 outline border-B-500 hover:border-B-400 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                                    iconBefore="ri-check-double-line"
                                    view="ghost"
                                    isDisabled={row.original.status === 'Assigned'}
                                    onClick={() => acceptAndAssign(row.original.leadId)}
                                />
                            </Tooltip>
                        </div>

                        <div>
                            <Tooltip id={uniqueId()} place="top" variant="dark" tooltipType="detailed" title="Edit">
                                <Button
                                    className="text-R-500 py-1 px-2 hover:text-R-400 outline border-R-500 hover:border-R-400 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                                    iconBefore="ri-edit-box-fill"
                                    view="ghost"
                                    onClick={() => openModal(row.original)}
                                />
                            </Tooltip>
                        </div>
                    </div>
                ),
            },
        ],
        []
    );

    return (
        <>
            <UITableV2
                className="z-[0]"
                width={100}
                widthInPercentage={true}
                tableData={tableData}
                columnsArray={tableColumnDefinition}
                bodyClassnames={tableData?.length >= 4 ? 'overflow-auto' : ''}
                isLoading={false}
                isLoadMoreTriggered={false}
                loadingText={'Loading lead suggestions...'}
                headerClassnames={tableData?.length <= 1 ? 'z-0' : ''}
            />

            <UIModalV2
                header={
                    <div className="flex items-center justify-between">
                        <div className="flex gap-x-3">
                            <i className="ri-body-scan-fill text-h6" />
                            <p className="text-md text-N-900 font-500">Assign New Sales Rep</p>
                        </div>
                    </div>
                }
                body={
                    <>
                        <div className="flex flex-col mt-3">
                            <p>Select Sales Rep: </p>
                            <select
                                name="salesRep"
                                id="salesRep-select"
                                className="mb-2 p-2 text-N-600 w-full"
                                onChange={handleSelectChange}
                                value={selectedSalesRep}
                            >
                                <option value="">Select sales rep</option>
                                {SALES_REP_INFO.map((rep, index) => (
                                    <option key={index} value={rep.firstName + ' ' + rep.lastName}>
                                        {rep.firstName} {rep.lastName} - {rep.geoLocation}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mt-3">
                            <p>Add a comment: </p>
                            <textarea
                                className="w-full p-4 my-1 border border-N-400 rounded-md resize-none"
                                value={comment}
                                onChange={handleCommentChange}
                                placeholder="Add a comment..."
                            />
                        </div>
                    </>
                }
                footer={
                    <Button
                        onClick={saveChanges}
                        size="medium"
                        view="ghost"
                        className="bg-B-500 p-2 hover:bg-B-400 text-white mt-4 self-end"
                    >
                        Save & Close
                    </Button>
                }
                open={isModalOpen}
                onClose={closeModal}
                height={350}
            />
        </>
    );
};

export default SalesRepActionTable;
