import { ChatMessage, CommonMessage, StepperTypes } from '../components/common';
import { ConfirmCreateKpl } from '../components/common/chat-with-documents/ConfirmCreateKpl';
import { IChatSteps } from '../components/common/custom-kpl-container/common/custom-kpl-create/common/ChatStepper';
import { KplFileUploader } from '../components/common/custom-kpl-container/common/custom-kpl-create/common/KplFileUploader';
import { KplPromptResponse } from '../components/common/custom-kpl-container/common/custom-kpl-create/common/KplPromptResponse';
import { ACCEPTED_FILE_LIST_IN_CUSTOM_KPL } from '../constants';
import { CustomKplSectionCreationSteps, useChatContext, useCustomKplContext } from '../contexts';
import { toConstantCase } from '../utils';
import { useAllKPLs } from './useAllKPLs';
import { useMobile } from './useMobile';

export const useCustomKplStepper = () => {
    const {
        name,
        files,
        currentStep,
        isFileUploaded,
        handleSubmit,
        tempPromptInstructions,
        promptInstructions,
        headerMetaDataList,
    } = useCustomKplContext();
    const { userFirstName } = useChatContext();
    const isMobile = useMobile();
    const { doesKPLConfigExist } = useAllKPLs();

    const kplCreateSteps: IChatSteps[] = [
        {
            id: CustomKplSectionCreationSteps.CREATE_NAME,
            response: (
                <ChatMessage
                    disableCopy
                    text={`Hi, ${userFirstName} 👋
                    \n Welcome to Bernie, your personal assistant for creating a customized Knowledge-Powered Language model (KPL). 
                        We're excited to guide you through this process via a simple conversation. Before we begin, please take a moment 
                        to review these important instructions:
                    \n1. Throughout our conversation, feel free to set your expectations for the KPL you'd like to create.
                    \n2. You can upload up to ${headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_SIZE_LIMIT}MB of documents to serve as the data collection for your KPL.
                    \n3. After creating your KPL, you'll be able to adjust configurations by navigating to ${isMobile ? '<br />' : ''} "Custom KPL" -> "Configure".
                    \n4. Your KPL will be active for ${headerMetaDataList?.remainingDays} days from the creation date. You'll receive an email notification when it expires.
                    \n5. You'll have ${headerMetaDataList?.metaDataConfigs?.GUEST_USER_MAX_TOKEN_COUNT} tokens available to interact with your newly created KPL. Once you've used all your tokens, you won't be able to continue interacting with Bernie. 
                    \n Now, let's begin the exciting process of creating your KPL!
                    <br/>First things first – what would you like to name your KPL?`}
                />
            ),
            isShowStep: true,
            inputs: name ? (
                <CommonMessage
                    isUser
                    disableCopy
                    text=""
                    content={
                        <>
                            {doesKPLConfigExist(toConstantCase(name)) ? (
                                <p className="text-R-600 text-xs">
                                    <i>A KPL key with this title already exists. Please enter a new one!</i>
                                </p>
                            ) : (
                                name
                            )}
                        </>
                    }
                />
            ) : null,
            type: StepperTypes.BOT_RESPONSE,
        },
        {
            id: CustomKplSectionCreationSteps.RESPONSE_CONFIGURE,
            response: (
                <ChatMessage
                    disableCopy
                    text={`Great Choice!
                    \n Now let’s define how a response should look like. Structure how the response should look like so once a 
                    query has been asked, response will follow the structure that you have provided.
                    \n **Prompt**: \n Act as a professional **[role]** with expertise in **[field]** . You have access to key information from **[source]** , and your role is to provide
                                     clear and actionable advice based on this data.
                    \n When assisting the user:

                    \n- **Analyze** : Break down complex details and explain them in simple terms.
                    \n- **Reference** : Use relevant sections of the information to ground your response.
                    \n- **Guide** : Help the user make informed decisions by highlighting risks or benefits. 
                    \n- **Be concise** : Offer professional, clear, and actionable advice.
                    \n- If information is missing, ask for more details or suggest areas for review.`}
                />
            ),
            isShowStep:
                currentStep === CustomKplSectionCreationSteps.RESPONSE_CONFIGURE || tempPromptInstructions
                    ? true
                    : false,
            inputs: tempPromptInstructions ? <KplPromptResponse /> : null,
            type: StepperTypes.BOT_RESPONSE,
        },
        {
            id: CustomKplSectionCreationSteps.UPLOAD_DOCUMENTS,
            response: (
                <ChatMessage
                    disableCopy
                    text={`
                    \nPlease upload the documents that Bernie needs to refer to when responding to user queries. Your documents should be less than 100 MBs. It could be multiple documents.`}
                />
            ),
            isShowStep:
                (currentStep === CustomKplSectionCreationSteps.UPLOAD_DOCUMENTS && promptInstructions) || isFileUploaded
                    ? true
                    : false,
            inputs: (
                <CommonMessage
                    isUser
                    disableCopy
                    text=""
                    content={
                        <KplFileUploader
                            files={files}
                            handleUpload={() => handleSubmit()}
                            isFileUploaded={isFileUploaded}
                            hasFileLimit={true}
                            hasFileSizeLimit={true}
                            maxFileCount={headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_COUNT_LIMIT}
                            maxFileSize={Number(headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_SIZE_LIMIT)}
                            showDocumentToggles={false}
                            acceptedFiles={ACCEPTED_FILE_LIST_IN_CUSTOM_KPL}
                        />
                    }
                />
            ), // Update this according to your logic
            type: StepperTypes.BOT_RESPONSE,
        },
        {
            id: CustomKplSectionCreationSteps.CONFIRM_PROCESSED,
            response: <ConfirmCreateKpl />,
            isShowStep: currentStep === CustomKplSectionCreationSteps.CONFIRM_PROCESSED || isFileUploaded,
            inputs: false,
            type: StepperTypes.BOT_RESPONSE,
        },
    ];

    return {
        kplCreateSteps,
    };
};
