import { Button, CheckBox, RadioButton, Select, TextField } from '@kaya/kaya-ui-design-system-pb';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export interface ItemsType {
    id: string;
    name: string;
}

interface ElementData {
    value: string;
    label: string;
}

interface ElementAction {
    type: string;
    target: string;
    message?: string;
}

interface ElementMeta {
    type: string;
    name: string;
    placeholder?: string;
    label?: string;
    data?: ElementData[];
    action?: ElementAction;
}

// Select Element
const SelectElement: React.FC<{ element: ElementMeta; onChange: (value: string) => void }> = ({
    element,
    onChange,
}) => {
    const items = element.data?.map(option => ({ id: option.value, name: option.label })) || [];

    const [selectedValue, setSelectedValue] = React.useState<ItemsType>(
        items[0] || {
            id: '',
            name: '',
        }
    );

    const handleSelectChange = (value: ItemsType) => {
        setSelectedValue(value);
        onChange(value.id);
    };

    return (
        <div className="mb-4 w-[400px]">
            {element.placeholder && (
                <legend className="block mb-2 font-medium text-gray-700 font-bold">{element.placeholder}</legend>
            )}
            <Select
                className="w-full"
                view="default"
                value={selectedValue}
                onChange={handleSelectChange}
                items={items}
            />
        </div>
    );
};

// Radio Element
const RadioElement: React.FC<{ element: ElementMeta; onChange: (value: string) => void }> = ({ element, onChange }) => {
    const [selectedValue, setSelectedValue] = React.useState<string>('');

    const handleChange = (value: string) => {
        setSelectedValue(value);
        onChange(value);
    };

    return (
        <fieldset className="mb-4">
            {element.label && (
                <legend className="block mb-2 font-medium text-gray-700 font-bold">{element.label}</legend>
            )}
            {element.data?.map((option, idx) => (
                <div key={idx} className="flex items-center mb-1">
                    <RadioButton
                        name={element.name}
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={() => handleChange(option.value)}
                        label={option.label}
                    />
                </div>
            ))}
        </fieldset>
    );
};

// CheckBox Element
const CheckBoxElement: React.FC<{ element: ElementMeta; onChange: (checked: boolean) => void }> = ({
    element,
    onChange,
}) => {
    const [checked, setChecked] = React.useState<boolean>(false);

    const handleCheckBoxChange = () => {
        setChecked(!checked);
        onChange(!checked);
    };

    return (
        <div className="mb-4">
            <CheckBox
                value="autoGenerate"
                checked={checked}
                onChange={handleCheckBoxChange}
                label={element.label || ''}
                id={element.name}
            />
        </div>
    );
};

// Input Element
const InputElement: React.FC<{ element: ElementMeta; onChange: (value: string) => void }> = ({ element, onChange }) => {
    const [value, setValue] = React.useState<string>('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue(newValue);
        onChange(newValue);
    };

    return (
        <div className="mb-4 w-[400px]">
            {element.label && (
                <label htmlFor={element.name} className="block mb-1 font-medium text-gray-700">
                    {element.label}
                </label>
            )}
            <TextField
                id={element.name}
                name={element.name}
                value={value}
                onChange={handleInputChange}
                placeholder={element.placeholder}
                className="w-full"
                wrapperClassName="w-full"
            />
        </div>
    );
};

// Button Element
const ButtonElement: React.FC<{ element: ElementMeta; onClick: () => void }> = ({ element, onClick }) => (
    <div className="mb-4">
        <Button
            type="button"
            className="bg-B-700 hover:bg-B-800 text-white text-md outline-none border-none font-bold py-2 px-4 my-1 rounded-md w-full flex items-center disabled:bg-B-500 disabled:cursor-not-allowed"
            onClick={onClick}
        >
            {element.label || 'Submit'}
        </Button>
    </div>
);

// Main DynamicElements Component
const DynamicElements: React.FC<{ elementsMeta: string; submitMessage: any }> = ({ elementsMeta, submitMessage }) => {
    const [elements, setElements] = React.useState<ElementMeta[]>([]);
    const [parseError, setParseError] = React.useState<string | null>(null);

    // State to store input values
    const [inputValues, setInputValues] = React.useState<{ [key: string]: any }>({});

    // Parse JSON safely with error handling
    React.useEffect(() => {
        try {
            const parsedElements: ElementMeta[] = JSON.parse(elementsMeta);
            setElements(parsedElements);
            setParseError(null);
        } catch (error) {
            console.error('Failed to parse elementsMeta:', error);
            setParseError('Invalid form configuration.');
        }
    }, [elementsMeta]);

    // Function to update input values
    const handleInputChange = (name: string, value: any) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // Replace variables in the message with actual values
    const replaceMessageVariables = (message: string) => {
        let formattedMessage = message;
        Object.keys(inputValues).forEach(key => {
            const value = inputValues[key];
            formattedMessage = formattedMessage.replace(`{${key}}`, value);
        });
        return formattedMessage;
    };

    // Submit handler for the button click
    const handleSubmit = async (message: string) => {
        const formattedMessage = replaceMessageVariables(message);
        try {
            await submitMessage(formattedMessage);
        } catch (error) {
            console.error('Error in submitMessage', error);
        }
    };

    // Render elements based on type
    const renderElement = (element: ElementMeta, index: number) => {
        switch (element.type.toLowerCase()) {
            case 'select':
                return (
                    <SelectElement
                        key={index}
                        element={element}
                        onChange={(value: string) => handleInputChange(element.name, value)}
                    />
                );
            case 'radio':
                return (
                    <RadioElement
                        key={index}
                        element={element}
                        onChange={(value: string) => handleInputChange(element.name, value)}
                    />
                );
            case 'checkbox':
                return (
                    <CheckBoxElement
                        key={index}
                        element={element}
                        onChange={(checked: boolean) => handleInputChange(element.name, checked)}
                    />
                );
            case 'input':
                return (
                    <InputElement
                        key={index}
                        element={element}
                        onChange={(value: string) => handleInputChange(element.name, value)}
                    />
                );
            case 'button':
                return (
                    <ButtonElement
                        key={index}
                        element={element}
                        onClick={() => handleSubmit(element.action?.message || '')}
                    />
                );
            default:
                return <div key={index}>Unsupported element type: {element.type}</div>;
        }
    };

    if (parseError || !elements || !elements.length) {
        return <></>;
    }

    return (
        <ErrorBoundary fallback={<></>}>
            <form>{elements.map((element, index) => renderElement(element, index))}</form>
        </ErrorBoundary>
    );
};

export { DynamicElements };
