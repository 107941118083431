import { CommonMessage, FormattedChatMessage, ModelSwitchChatMessage, SMEChatMessage } from '.';
import { MessageType } from '../../../__generated__/graphql';
import { FEATURE_MODEL_TEMP_ID, SYSTEM_MODEL_TEMP_ID } from '../../../constants';
import { IChatMessage } from '../../pages/messenger/types';
import { AddCallReports } from '../add-call-reports';
import { AddCallTranscripts } from '../add-call-transcripts';
import { AddTestCases } from '../add-test-cases';
import { AddTimesheet } from '../add-timesheet';
import { ChatWithDocuments } from '../chat-with-documents';
import { DownloadCallReport } from './DownloadCallReport';
import { DownloadTestCases } from './DownloadTestCases';
import { RouteCallReports } from './RouteCallReports';
import { DynamicElements } from './DynamicElements';
import { useMessageContent } from '../../../hooks';

const ChatMessage = ({
    id = '',
    index,
    text = '',
    sources = [],
    feedback,
    isUser = false,
    disableCopy = false,
    time,
    type,
    submitMessage,
    enableCallReports,
    enableTestCaseAnalysis,
    elements,
}: IChatMessage) => {
    const { currentAppConfig } = useMessageContent();

    if (id.includes(SYSTEM_MODEL_TEMP_ID)) {
        return <ModelSwitchChatMessage id={id} text={text} time={time} />;
    }

    if (id.includes(FEATURE_MODEL_TEMP_ID)) {
        return <ModelSwitchChatMessage id={id} text={text} time={time} />;
    }

    if (type === MessageType.SmeResponse) {
        return <SMEChatMessage id={id} text={text} isUser={isUser} feedback={feedback} disableCopy={disableCopy} />;
    }

    if (type === MessageType.DocumentInput) {
        return <ChatWithDocuments />;
    }

    if (type === MessageType.TimesheetInput) {
        return <AddTimesheet />;
    }

    if (type === MessageType.CallReportsInput && submitMessage) {
        return <AddCallReports submitMessage={submitMessage} />;
    }

    if (type === MessageType.CallTranscriptInput) {
        return <AddCallTranscripts />;
    }

    if (type === MessageType.TestCasesInput) {
        return <AddTestCases />;
    }

    if (enableCallReports && isUser) {
        return null;
    }

    /**
     * NOTE:
     * Remove this conditional check for the @param customWelcomeMessage
     * once the implementation for Fast Food and Beverage Industry KPL is
     * completed from BE site
     */
    if (currentAppConfig?.metadata.customWelcomeMessage?.enabled) {
        const overriddenQuestions = currentAppConfig?.metadata.overridePredefinedQuestions?.questions;
        const messageToReplace = overriddenQuestions.find(
            question => question.value.toLowerCase() === text.toLowerCase()
        );

        return (
            <CommonMessage
                index={index}
                id={id}
                text={text}
                isUser={isUser}
                feedback={feedback}
                disableCopy={disableCopy}
                content={
                    <>
                        <div className={`${isUser ? 'max-w-[500px]' : 'max-w-[80%]'} sm:max-w-full`}>
                            <FormattedChatMessage
                                text={
                                    text.toLowerCase() === messageToReplace?.value.toLowerCase()
                                        ? messageToReplace.key
                                        : text
                                }
                                sources={sources}
                            />
                        </div>
                        {!isUser && !disableCopy && (
                            <>
                                <RouteCallReports messageId={id} enableCallReports={Boolean(enableCallReports)} />
                                <DownloadCallReport
                                    messageId={id}
                                    text={text}
                                    enableCallReports={Boolean(enableCallReports)}
                                />
                                <DownloadTestCases
                                    messageId={id}
                                    text={text}
                                    enableTestCaseAnalysis={Boolean(enableTestCaseAnalysis)}
                                />
                            </>
                        )}
                    </>
                }
            />
        );
    }

    return (
        <CommonMessage
            index={index}
            id={id}
            text={text}
            isUser={isUser}
            feedback={feedback}
            disableCopy={disableCopy}
            content={
                <>
                    <div className={`${isUser ? 'max-w-[500px]' : 'max-w-[80%]'} sm:max-w-full`}>
                        <FormattedChatMessage text={text} sources={sources} />
                    </div>
                    {!isUser && !disableCopy && (
                        <>
                            <DynamicElements elementsMeta={elements} submitMessage={submitMessage} />
                            <RouteCallReports messageId={id} enableCallReports={Boolean(enableCallReports)} />
                            <DownloadCallReport
                                messageId={id}
                                text={text}
                                enableCallReports={Boolean(enableCallReports)}
                            />
                            <DownloadTestCases
                                messageId={id}
                                text={text}
                                enableTestCaseAnalysis={Boolean(enableTestCaseAnalysis)}
                            />
                        </>
                    )}
                </>
            }
        />
    );
};

export { ChatMessage };
