import { TextField } from '@kaya/kaya-ui-design-system-pb';
import { useState } from 'react';
import { ModelType } from '../../../../../__generated__/graphql';
import { useAdminPanelContext } from '../../../../../contexts';
import { ModelSelect } from '../../../custom-select-components';
import { ExplanationTooltip } from '../explanation-tooltip';

export const SupportiveFormInnerPrompt = () => {
    const [currentModel, setCurrentModel] = useState<ModelType>(ModelType.OpenAi);
    const { kplFormData, setKPLFormData } = useAdminPanelContext();

    const textToRemove =
        /\s*\*\*Knowledge Hub Content:\*\* \{information\}\s*\*\*User Uploaded Document Content:\*\* \{document_content\}\s*/g;
    const addText =
        '\n\n**Knowledge Hub Content:** {information}\n**User Uploaded Document Content:** {document_content}';
    const removeText = (text: string) => text.replace(textToRemove, '');

    return (
        <div>
            <div className="step-supportive-prompts-content flex flex-col gap-y-6">
                <div className="flex flex-col gap-y-5">
                    <div className="flex flex-col gap-y-2 w-full">
                        <p className="text-xs font-500 text-N-900 flex gap-x-1">Select Model</p>
                        <ModelSelect
                            onChange={(value: any) => {
                                setCurrentModel(value.model);
                            }}
                        />
                    </div>

                    <div className="flex flex-col gap-y-2 w-full">
                        <p className="text-xs font-500 text-N-900 flex gap-x-1">
                            Prompt Template
                            <ExplanationTooltip
                                id="icon-tooltip"
                                description="Please enter a precise and clear prompt to guide the AI's response. Use labeled placeholders for variables (e.g., `{information}`, `{document_content}`) to make the prompt adaptable and dynamic."
                            />
                        </p>
                        <TextField
                            step="1"
                            view="default"
                            wrapperClassName="w-full"
                            placeholder="Prompt template"
                            type="text"
                            autoComplete="nope"
                            className="min-h-[235px]"
                            rows={25}
                            value={removeText(
                                kplFormData.prompts.find(prompt => prompt.model === currentModel)?.prompt || ''
                            )}
                            onChange={(e: any) => {
                                const newValue = e?.target?.value;
                                setKPLFormData(kplFormData => ({
                                    ...kplFormData,
                                    prompts: kplFormData.prompts.map(prompt => {
                                        if (prompt.model === currentModel) {
                                            return {
                                                ...prompt,
                                                prompt: newValue,
                                            };
                                        }
                                        return prompt;
                                    }),
                                }));
                            }}
                        />
                    </div>
                </div>
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};
