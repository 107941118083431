import { Avatar, Button, TextField, Tooltip, UIModalV2 } from '@kaya/kaya-ui-design-system-pb';
import React, { useState } from 'react';
import { useExternalUserManageTable, useMobile } from '../../../hooks';
import { CN, getInitials, uniqueId } from '../../../utils';
import { UITableV2 } from '../table';
import { IAdminFilterExternalUserContainerProps } from '../external-user-management-container';
import { UPDATE_KPL_REQUEST_STATUS } from '../../../graphql';
import { useMutation } from '@apollo/client';
import { KplRequestUserStatus } from '../../../__generated__/graphql';
import { toast } from 'react-toastify';

export const ExternalUserTableContainer = ({ filterExternalUserCriteria }: IAdminFilterExternalUserContainerProps) => {
    const isMobile = useMobile();
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
    const {
        tableColumnDefinition,
        tableData,
        scrollableRef,
        setSearchTerm,
        tableElementId,
        selectedProfile,
        isLoadingMore,
        loading,
        reloading,
        tableLoadingText,
        isApproveModelOpen,
        setIsApproveModelOpen,
        isRejectModelOpen,
        setIsRejectModelOpen,
        refetchData,
        sortBy,
    } = useExternalUserManageTable(filterExternalUserCriteria);

    const [updateKplRequestStatus] = useMutation(UPDATE_KPL_REQUEST_STATUS);

    const handleKplRequestStatus = async () => {
        try {
            setIsButtonLoading(true);
            await updateKplRequestStatus({
                variables: {
                    input: {
                        email: selectedProfile?.user?.email,
                        status: isApproveModelOpen ? KplRequestUserStatus.Approved : KplRequestUserStatus.Rejected,
                    },
                },
            });
            toast.success(`KPL request ${isApproveModelOpen ? 'approved' : 'rejected'} successfully`);
        } catch (error) {
            toast.error('Error updating KPL request status');
        } finally {
            setTimeout(() => {
                setIsButtonLoading(false);
                setIsApproveModelOpen(false);
                setIsRejectModelOpen(false);
                refetchData();
            }, 1000);
        }
    };

    return (
        <>
            <div className="admin-panel__table-section flex sm:w-full flex-col gap-y-4 overflow-auto w-full h-full">
                <div className="admin-panel__search flex sm:flex-col text-start sm:items-start items-center gap-x-20 justify-between">
                    <div className="flex gap-x-3">
                        <p className="text-N-600 text-start whitespace-nowrap mb-2">
                            Showing <span className="text-N-900 font-600">{tableData.length}</span> External Users
                        </p>
                        <Tooltip
                            id={uniqueId()}
                            title={'Reload Users'}
                            tooltipType="minimal"
                            variant="dark"
                            place="right"
                        >
                            <div
                                className={CN('cursor-pointer', {
                                    'animate-spin': reloading,
                                })}
                                onClick={() => refetchData()}
                            >
                                <i
                                    onClick={() => refetchData()}
                                    className={CN(
                                        'ri-refresh-line text-sm font-500 text-N-900 cursor-pointer hover:text-[#1D5BD7] hover:font-bold',
                                        {
                                            'animate-spin': loading,
                                        }
                                    )}
                                />
                            </div>
                        </Tooltip>
                    </div>
                    <TextField
                        view="default"
                        iconBefore="ri-search-line"
                        placeholder="Search User by name"
                        wrapperClassName="w-full mr-[10px]"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                    />
                </div>
                <div className="admin-panel__table w-full h-full">
                    <div
                        className={CN(
                            'w-full sm:overflow-x-scroll max-h-[calc(100vh-220px)] overflow-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 h-full',
                            {
                                'min-h-[400px]': !tableData?.length,
                            }
                        )}
                        ref={scrollableRef}
                        id={tableElementId}
                    >
                        <UITableV2
                            className="z-[0]"
                            width={100}
                            widthInPercentage={true}
                            tableData={tableData}
                            columnsArray={tableColumnDefinition}
                            bodyClassnames={tableData?.length >= 5 ? 'overflow-auto' : ''}
                            isLoading={loading || isLoadingMore || reloading}
                            isLoadMoreTriggered={isLoadingMore}
                            loadingText={tableLoadingText}
                            headerClassnames={tableData?.length <= 1 ? 'z-0' : ''}
                            defaultSortBy={sortBy}
                        />
                    </div>

                    <UIModalV2
                        width={500}
                        header={isApproveModelOpen ? 'Approve Request?' : 'Reject Request?'}
                        body={
                            <>
                                <div className="p-5 flex flex-col items-center justify-center">
                                    <p>
                                        {`You are about to ${isApproveModelOpen ? 'approve' : 'reject'}`}{' '}
                                        <span className="font-700">{`${selectedProfile?.user?.firstName} ${selectedProfile?.user?.lastName}'s `}</span>
                                        {` request`}
                                    </p>
                                    <div className="mt-10 flex items-center">
                                        <Avatar
                                            imageURL={selectedProfile?.avatarUrl}
                                            className="cursor-pointer"
                                            shape="circle"
                                            size="md"
                                            type={'text'}
                                            icon="ri-user-3-line"
                                            textLabel={getInitials(
                                                `${selectedProfile?.user?.firstName} ${selectedProfile?.user?.lastName}`
                                            )}
                                        />

                                        {/* Dots between Avatars */}
                                        <div className="mx-3 flex space-x-1">
                                            <span className="w-1 h-1 bg-N-300 rounded-full"></span>
                                            <span className="w-1 h-1 bg-N-300 rounded-full"></span>
                                            <span className="w-1 h-1 bg-N-300 rounded-full"></span>
                                        </div>

                                        {/* Separate the two logic statements. KA-1476: Display the reject popup for a second when canceling the approval popup */}
                                        {isApproveModelOpen && (
                                            <div className="w-10 h-10 rounded-full bg-B-500 p-3 flex items-center justify-center">
                                                <i
                                                    className={`${isApproveModelOpen && 'ri-user-follow-fill'} text-white text-[25px] font-500`}
                                                ></i>
                                            </div>
                                        )}

                                        {isRejectModelOpen && (
                                            <div className="w-10 h-10 rounded-full bg-B-500 p-3 flex items-center justify-center">
                                                <i
                                                    className={`${isRejectModelOpen && 'ri-user-unfollow-fill'} text-white text-[25px] font-500`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                    {isRejectModelOpen && (
                                        <p className="mt-5 text-xs text-R-500">
                                            Please note that if a request is rejected, it cannot be reversed.
                                        </p>
                                    )}
                                </div>
                            </>
                        }
                        footer={
                            <div className="flex items-center justify-end">
                                <Button
                                    view="solid"
                                    appearance="primaryBlack"
                                    size="small"
                                    className="whitespace-nowrap"
                                    onClick={() => {
                                        isApproveModelOpen ? setIsApproveModelOpen(false) : setIsRejectModelOpen(false);
                                    }}
                                    isDisabled={isButtonLoading}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    view="solid"
                                    appearance="primary"
                                    size="small"
                                    className="ms-3"
                                    isLoading={isButtonLoading}
                                    onClick={handleKplRequestStatus}
                                >
                                    {isApproveModelOpen ? 'Approve' : 'Reject'}
                                </Button>
                            </div>
                        }
                        open={isApproveModelOpen || isRejectModelOpen}
                        onClose={() => {
                            setIsApproveModelOpen(false);
                            setIsRejectModelOpen(false);
                        }}
                        isClosable={!isButtonLoading}
                    />
                </div>
            </div>
        </>
    );
};
