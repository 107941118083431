import { Button, TextField, Tooltip, CheckBox } from '@kaya/kaya-ui-design-system-pb';
import { KnowledgeDocument } from './common/knowledge-document/KnowledgeDocument';
import { useEffect, useRef, useState } from 'react';
import { useChatContext, useCustomKplContext } from '../../../../../contexts';
import { ConversationStarters } from './common/conversation-starters/ConversationStarters';
import { CN, uniqueId } from '../../../../../utils';
import { ACCEPTED_FILE_LIST_IN_CUSTOM_KPL } from '../../../../../constants';
import { useMobile } from '../../../../../hooks';

export const CustomKplConfigure = () => {
    const fileRef = useRef<HTMLInputElement | null>(null);
    const isMobile = useMobile();
    const {
        files,
        setFiles,
        name,
        setName,
        promptInstructions,
        setPromptInstructions,
        isLoading,
        handleUpdate,
        handlePromptEnhancement,
        promptEnhanceLoading,
        fetchConversationStarters,
        isQuestionGenerated,
        headerMetaDataList,
        setRemovedFiles,
        setUpdatedFiles,
        isQuestionLoading,
        fetchSavedKplData,
        isFormEdited,
        setIsFormEdited,
        unreadableFiles,
        failedFileUploads,
        initialFiles,
    } = useCustomKplContext();

    const { isShowKplGuildLines, setIsShowKplGuildLines } = useChatContext();

    const [warningMessage, setWarningMessage] = useState<string>('');
    const [isFileLimitationValid, setIsFileLimitationValid] = useState<boolean>(true);
    const [isUploadBtnDisable, setIsUploadBtnDisable] = useState<boolean>(false);
    const [initialKplName, setInitialKplName] = useState(name);
    const [initialPromptInstructions, setInitialPromptInstructions] = useState(promptInstructions);
    const [initialIsShowPreDefinedQuestions, setInitialIsShowPreDefinedQuestions] =
        useState<boolean>(isShowKplGuildLines);

    const compareFileNames = (files1: File[], files2: File[]): boolean => {
        const fileNamesArray1 = files1.map(file => file.name);
        const fileNamesArray2 = files2.map(file => file.name);

        fileNamesArray1.sort();
        fileNamesArray2.sort();

        if (fileNamesArray1.length !== fileNamesArray2.length) {
            return false;
        }

        for (let i = 0; i < fileNamesArray1.length; i++) {
            if (fileNamesArray1[i] !== fileNamesArray2[i]) {
                return false;
            }
        }

        return true;
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newName = e.target.value;
        setName(newName);
        setIsFormEdited(newName !== initialKplName);
    };

    const handlePromptInstructionsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newInstructions = e.target.value;
        setPromptInstructions(newInstructions);
        setIsFormEdited(newInstructions !== initialPromptInstructions);
    };

    const handlePreDefinedQuestionsVisibilityChange = () => {
        setIsShowKplGuildLines(!isShowKplGuildLines);
        if (
            promptInstructions === initialPromptInstructions &&
            name === initialKplName &&
            compareFileNames(initialFiles, files)
        ) {
            setIsFormEdited(initialIsShowPreDefinedQuestions === isShowKplGuildLines);
        }
    };

    const handleUpload = () => {
        const fileInput = fileRef?.current;

        if (fileInput) {
            fileInput?.click();
        }
    };

    const normalizeFileName = (fileName: string) => {
        return fileName.replace(/\s/g, '_');
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.files?.length) return null;

        const selectedFiles = Array.from(event.target.files);

        const existingFileNames = files.map(file => ({
            original: file.name,
            normalized: normalizeFileName(file.name),
        }));

        const { duplicates, nonDuplicates } = selectedFiles.reduce(
            (acc, file) => {
                const normalizedFileName = normalizeFileName(file.name);

                const isDuplicate = existingFileNames.some(
                    existingFile =>
                        existingFile.original === file.name || existingFile.normalized === normalizedFileName
                );

                if (isDuplicate) {
                    acc.duplicates.push(file);
                } else {
                    acc.nonDuplicates.push(file);
                }
                return acc;
            },
            { duplicates: [] as File[], nonDuplicates: [] as File[] }
        );

        if (nonDuplicates.length > 0) {
            setFiles((prevFiles: File[]) => [...prevFiles, ...nonDuplicates]);
            setUpdatedFiles((prevUpdatedFiles: File[]) => [...prevUpdatedFiles, ...nonDuplicates]);
            setIsFormEdited(true);
            setWarningMessage('');
        }

        if (duplicates.length > 0) {
            const duplicateNames = duplicates.map(file => file.name).join(', ');
            setWarningMessage(`The following files are duplicates and were not added: ${duplicateNames}`);
        }
        event.target.value = '';
    };

    const onRemoveFileSave = (removedFile: any) => {
        setRemovedFiles((prevRemovedFiles: string[]) => [...prevRemovedFiles, removedFile.id]);
    };

    const onClose = (index: number) => {
        const removedFile = files[index];
        setFiles((prevFiles: File[]) => prevFiles.filter((_, i) => i !== index));
        onRemoveFileSave(removedFile);
        setUpdatedFiles((prevUpdatedFiles: File[]) => prevUpdatedFiles.filter(file => file.name !== removedFile.name));
        setIsFormEdited(true);
    };

    useEffect(() => {
        const totalSize = files.reduce((acc, file) => acc + file.size, 0);

        if (totalSize > headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_SIZE_LIMIT * 1024 * 1024) {
            setWarningMessage(
                `Total file size exceeds the maximum limit of ${headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_SIZE_LIMIT} MB.`
            );
            setIsFileLimitationValid(false);
        } else if (totalSize === headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_SIZE_LIMIT * 1024 * 1024) {
            setWarningMessage('');
            setIsUploadBtnDisable(true);
            setIsFileLimitationValid(true);
        } else if (files.length > headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_COUNT_LIMIT) {
            setWarningMessage(
                `You can only upload a maximum of ${headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_COUNT_LIMIT} files.`
            );
            setIsFileLimitationValid(false);
        } else if (files.length === headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_COUNT_LIMIT) {
            setWarningMessage('');
            setIsUploadBtnDisable(true);
            setIsFileLimitationValid(true);
        } else {
            setWarningMessage('');
            setIsFileLimitationValid(true);
            setIsUploadBtnDisable(false);
        }

        setIsFormEdited(!compareFileNames(initialFiles, files));
    }, [files, headerMetaDataList, initialFiles]);

    useEffect(() => {
        fetchSavedKplData();
    }, []);

    useEffect(() => {
        setInitialKplName(name);
        setInitialPromptInstructions(promptInstructions);
        setInitialIsShowPreDefinedQuestions(isShowKplGuildLines);
    }, []);

    useEffect(() => {
        if (isMobile) {
            const input = document.getElementById('uploadInput-custom-kpl-config-id');

            const handleFileTypes = (event: any) => {
                const files = Array.from(event.target.files);
                const invalidFiles = files.filter((file: any) => !ACCEPTED_FILE_LIST_IN_CUSTOM_KPL.includes(file.type));

                if (invalidFiles.length > 0) {
                    setWarningMessage('Invalid file type. Please select a valid file.');
                    event.target.value = '';
                } else {
                    handleFileSelect(event);
                    setWarningMessage('');
                }
            };

            input?.addEventListener('change', handleFileTypes);
            return () => {
                input?.removeEventListener('change', handleFileTypes);
            };
        }
    }, [handleFileSelect]);

    return (
        <div className="h-auto w-[800px] md:w-full flex flex-col gap-y-5 items-start p-4">
            <div className='"flex flex-col gap-y-1 w-full items-end"'>
                <TextField
                    step="1"
                    view="default"
                    label="Name"
                    wrapperClassName="w-full"
                    placeholder="Name"
                    value={name}
                    onChange={handleNameChange}
                    type="text"
                    autoComplete="nope"
                    className="h-[45px] min-w-[216px]"
                    required={true}
                />
            </div>

            <div className="flex flex-col gap-y-[4px] w-full">
                <div className="flex items-center justify-between">
                    <span className='text-xs text-N-900 font-500 flex items-center w-full cursor-default after:content-["*"] after:ml-[2px] after:text-R-500 after:inline-flex after:items-center after:mt-[4px] after:leading-[0] justify-start'>
                        Response Prompt Instructions
                    </span>
                </div>
                <TextField
                    step="1"
                    view="default"
                    wrapperClassName="w-full"
                    placeholder="Response Prompt Instructions"
                    type="text"
                    autoComplete="nope"
                    className="min-h-[235px]"
                    rows={10}
                    onChange={handlePromptInstructionsChange}
                    required={true}
                    value={promptInstructions}
                />
                <div className="flex justify-end items-center">
                    <Button
                        size="medium"
                        view="outline"
                        appearance="primaryBlack"
                        className={CN(
                            'text-sm font-500 text-N-900 cursor-pointer hover:text-[#1D5BD7] hover:font-bold flex'
                        )}
                        iconBefore={promptEnhanceLoading ? '' : 'ri-sparkling-2-fill'}
                        onClick={() => {
                            !promptEnhanceLoading ? handlePromptEnhancement(promptInstructions) : () => {};
                            setIsFormEdited(true);
                        }}
                        isLoading={promptEnhanceLoading}
                    >
                        <span className="text-nowrap">Enhance prompt</span>
                    </Button>
                </div>
            </div>
            <div className="flex flex-col gap-3 w-full">
                <span className="text-xs text-N-900 font-500 w-full cursor-default">Knowledge</span>
                <div className="flex flex-col gap-2">
                    <div className="flex gap-3 w-full flex-wrap">
                        {files?.length ? (
                            files.map((file, i) => (
                                <KnowledgeDocument
                                    name={file?.name}
                                    key={i}
                                    onClose={() => onClose(i)}
                                    fileSize={file?.size}
                                />
                            ))
                        ) : (
                            <span className=" text-A-600">No files uploaded. Please select files to upload.</span>
                        )}
                    </div>
                    <p className="mt-2 text-xs text-R-600">
                        <i>{warningMessage && warningMessage}</i>
                    </p>

                    <div className="mt-1">
                        {unreadableFiles.length > 0 && (
                            <div className="bg-red-100 py-4 rounded-lg mb-4">
                                <p className="text-red-700 font-semibold my-2">Unreadable Files Detected</p>
                                <ul className="list-disc list-inside mt-2">
                                    {unreadableFiles.map((file, index) => (
                                        <li key={index} className="text-sm text-red-600">
                                            <i className="fas fa-exclamation-circle mr-2 text-red-500"></i>
                                            <i className="text-xs text-R-600">{file}</i>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {failedFileUploads.length > 0 && (
                            <div className="bg-yellow-100 py-4 rounded-lg">
                                <p className="text-yellow-700 font-semibold">Failed File Uploads</p>
                                <ul className="list-disc list-inside my-2">
                                    {failedFileUploads.map((file, index) => (
                                        <li key={index} className="text-sm text-yellow-600">
                                            <i className="fas fa-upload mr-2 text-yellow-500"></i>
                                            <i className="text-xs text-R-600">{file}</i>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className="flex gap-x-4 gap-y-2 flex-wrap items-start flex-row">
                        <div className="flex items-center gap-2">
                            <div className="text-xs">
                                <span className="text-N-700 font-medium">Knowledge Document Used:</span>
                                <span className="ml-1">
                                    {(headerMetaDataList?.totalDocumentSize / (1024 * 1024)).toFixed(2)}/
                                    {headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_SIZE_LIMIT} MB
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <div className="text-xs">
                                <span className="text-N-700 font-medium">Documents ingested:</span>
                                <span className="ml-1">
                                    {headerMetaDataList?.totalDocumentCount}/
                                    {headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_COUNT_LIMIT}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-start flex-col gap-y-3">
                            <Button
                                appearance="primary"
                                size="medium"
                                view="outline"
                                onClick={handleUpload}
                                isDisabled={!isFileLimitationValid || isUploadBtnDisable}
                                iconBefore="ri-upload-line"
                            >
                                Upload
                            </Button>
                            <input
                                id="uploadInput-custom-kpl-config-id"
                                type="file"
                                ref={fileRef}
                                multiple
                                className="hidden"
                                accept={ACCEPTED_FILE_LIST_IN_CUSTOM_KPL}
                                {...(isMobile ? { onChange: undefined } : { onChange: handleFileSelect })}
                            />
                            <CheckBox
                                checked={!isShowKplGuildLines}
                                onChange={() => {
                                    handlePreDefinedQuestionsVisibilityChange();
                                }}
                                label="Hide Pre-defined Questions"
                                id={uniqueId()}
                            />
                        </div>

                        <div className="flex items-center gap-x-3">
                            <Button
                                appearance="primary"
                                size="medium"
                                view="solid"
                                onClick={() => handleUpdate()}
                                isLoading={isLoading}
                                isDisabled={
                                    promptEnhanceLoading || isLoading || !isFileLimitationValid || !isFormEdited
                                }
                            >
                                Save changes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-2 w-full">
                <hr className="text-N-300 font-400 my-5 " />

                <span className="text-xs flex items-center justify-start gap-x-1 text-N-900 font-500 w-full cursor-default">
                    <p>Generate Conversation Starters</p>
                    <span>
                        <Tooltip
                            id={uniqueId()}
                            place="bottom"
                            subText={
                                'Once the conversation starters has been generated, those will be automatically saved and available in the KPL'
                            }
                            variant="dark"
                            tooltipType="detailed"
                        >
                            <i className="ri-information-2-line text-xs relative" />
                        </Tooltip>
                    </span>
                </span>
                <Button
                    appearance="primary"
                    size="small"
                    view="solid"
                    onClick={() => fetchConversationStarters()}
                    className={CN('w-[120px]')}
                    isDisabled={isQuestionGenerated}
                    isLoading={isQuestionLoading}
                >
                    Generate
                </Button>
                <div className="w-full border border-dashed p-4 flex justify-center items-center min-h-[200px] text-center">
                    <ConversationStarters />
                </div>
            </div>
        </div>
    );
};
