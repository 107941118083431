import { ChatType, FeatureType, MessageSettingsInput, ModelType } from '../__generated__/graphql';
import { ALL_VALUE_KPL, DEFAULT_MODELS, MODEL_TYPES } from '../constants';
import { AppConfigType, AppUsersConfigType, ModelListType } from '../contexts';
import { logger } from './logger';

export function getAutoExpand(email: string): boolean {
    return true;
}

export function getFullScreenOption(): boolean {
    return true;
}

export function getImprovedResponse(email: string): string {
    return localStorage.getItem(`aia_response_type-${email}`) || '';
}

export function getFeaturesToDisplay(userConfig: AppUsersConfigType | null): string {
    return localStorage.getItem(`aia_visible_features-${userConfig?.email}`) || userConfig?.features || ALL_VALUE_KPL;
}

export function getDefaultTabToDisplay(userConfig: AppUsersConfigType): string {
    return userConfig?.metadata?.default?.feature || FeatureType.General;
}

export function getDefaultSettings(userConfig: AppUsersConfigType): MessageSettingsInput {
    const defaultUserConfig = userConfig?.metadata;

    return {
        feature: defaultUserConfig?.default?.feature || FeatureType.General,
        model: defaultUserConfig?.default?.model || ModelType.OpenAi,
        type: getImprovedResponse(userConfig?.email),
        hasTemporaryFiles: false,
        options: {
            dynamicPrompts: defaultUserConfig?.default?.dynamicPrompts || false,
            webSearch: defaultUserConfig?.default?.webSearch || false,
            noLLM: defaultUserConfig?.default?.noLLM || false,
        },
    };
}

export function getFilteredFeaturesByEmail(
    userConfig: AppUsersConfigType | null,
    features: AppConfigType[],
    isGuestKplCreated?: boolean,
    isCurrentUserGuest?: boolean
): AppConfigType[] {
    try {
        const featureTypesToDisplay = getFeaturesToDisplay(userConfig);
        let finalFeatures: AppConfigType[] = [];
        if (featureTypesToDisplay === ALL_VALUE_KPL) {
            finalFeatures = [...features];
        } else {
            const featuresTypeArray = featureTypesToDisplay.split(',').map(type => type.trim());
            finalFeatures = features.filter(feature => featuresTypeArray.includes(feature.configKey));
        }

        finalFeatures.sort((a, b) => {
            if (a.chatType === ChatType.Assistant && b.chatType !== ChatType.Assistant) return -1;
            if (a.chatType !== ChatType.Assistant && b.chatType === ChatType.Assistant) return 1;
            return isGuestKplCreated && isCurrentUserGuest ? b.id - a.id : a.id - b.id;
        });

        return finalFeatures;
    } catch (error) {
        logger.error('Error filtering features by email:', error);
        return features;
    }
}

export function getFilteredModelsByUser(userConfig: AppUsersConfigType | null): ModelListType[] {
    const models = userConfig?.models ?? DEFAULT_MODELS;
    return MODEL_TYPES.filter(model => models.includes(model.model));
}

export function getCustomMessage(email: string): string {
    return localStorage.getItem(`aia_message-${email}`) || '';
}

export function clearCustomization(email: string, message: string): void {
    const exitMessage = localStorage.getItem(`aia_exit_message`) || 'Thank you Bernie';

    if (message === exitMessage) {
        localStorage.removeItem(`aia_message-${email}`);
    }
}

export function getLocalGuidelines(email: string): string[] | null {
    try {
        return JSON.parse(localStorage.getItem(`aia_get_started_questions-${email}`) || 'null');
    } catch {
        return null;
    }
}

export function getCallReports(email: string): boolean {
    return localStorage.getItem(`aia_call_reports-${email}`) === 'enabled';
}

export function getCallTranscripts(email: string): boolean {
    return localStorage.getItem(`aia_call_transcripts-${email}`) === 'enabled';
}

export function getCallReportsUser(email: string): string {
    return localStorage.getItem(`aia_user_call_reports-${email}`) || 'Ishan Jayasekera';
}

export function getTestCaseSuccessMessage(): string {
    return (
        localStorage.getItem(`aia_test_case_success`) ||
        `Test Cases added successfully ✅, You can now proceed with the questions.`
    );
}

export function convertKplConfigToTitle(input: string): string {
    return input
        .toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
