import { MultiSelectableInput } from '@kaya/kaya-ui-design-system-pb';
import { useEffect, useState } from 'react';
import { useAllKPLs } from '../../../hooks';

interface IKPLMultiSelectProps {
    onKPLChange: Function;
    width?: string;
    taglength?: number;
    selectedKpls: any;
}

export const KPLMultiSelect = ({ onKPLChange, width = '', taglength = 3, selectedKpls }: IKPLMultiSelectProps) => {
    const [currentKPLs, setCurrentKPLs] = useState<any[]>([]);
    const { KPL_SELECT_LIST } = useAllKPLs();

    useEffect(() => {
        const filteredAndSortedKPLs = KPL_SELECT_LIST?.filter(kpl => selectedKpls?.includes(kpl?.id))?.sort((a, b) =>
            a?.name.localeCompare(b?.name)
        );
        setCurrentKPLs(filteredAndSortedKPLs || []);
    }, [KPL_SELECT_LIST, selectedKpls]);

    return (
        <MultiSelectableInput
            taglength={taglength}
            width={width}
            showTags
            selectedValues={currentKPLs}
            placeholder="Select KPLs"
            selectableItems={KPL_SELECT_LIST}
            onSelect={(kpl: any) => {
                onKPLChange(kpl);
            }}
        />
    );
};
