import { useEffect, useState } from 'react';
import { FeatureType, Source } from '../../../__generated__/graphql';
import { IHighlightedPDFReferenceType, useChatContentContext, useChatContext } from '../../../contexts';
import { CustomSource, ReferenceCard } from '../reference-card';
import { truncateWithEllipsis } from '../../../utils';

type ReferencesProps = {
    sources?: Source[];
};

const References = ({ sources }: ReferencesProps) => {
    const [pdfHighlightedReferences, setPdfHighlightedReferences] = useState<any[]>();

    const { featureType } = useChatContext();

    if (featureType === FeatureType.KayaKbRecruitment) return null;

    const getPage = (source: Source) => {
        try {
            const sources = String(source?.sources?.[0])?.split('#');
            const page = Number(sources?.[1]?.replace('page=', '') || 0);
            return page || 0;
        } catch (error) {
            console.error('Error occurred while getting page:', error);
            return 0;
        }
    };

    const getFileName = (url: string) => {
        try {
            const regex = /\/([^\/?#;]+(\.pdf|\.txt|\.csv|\.docx))(?:\?|#|;|$)/i;
            const match = url.match(regex);
            return match ? match[1] : url;
        } catch (error) {
            console.error('Error occurred while getting file name:', error);
            return url;
        }
    };

    const getSanitizedURL = (url: string) => {
        try {
            const fileName = getFileName(url);
            const sanitizedFileName = decodeURIComponent(fileName).includes('/')
                ? decodeURIComponent(fileName).split('/').pop()
                : decodeURIComponent(fileName);
            return fileName ? truncateWithEllipsis(String(sanitizedFileName), 50) : url;
        } catch (error) {
            console.error('Error occurred while sanitizing URL:', error);
            return url;
        }
    };

    const getReference = (source: CustomSource) => {
        return `${getSanitizedURL(String(source.domainUrl))} ${source.page ? ` | Page: ${source.page}` : ''}`;
    };

    const isPDFReference = (url: string) => {
        return url.toLowerCase().endsWith('.pdf');
    };

    const initialSources: CustomSource[] =
        sources?.map(source => ({
            ...source,
            page: getPage(source),
        })) || [];

    useEffect(() => {
        const highlightedReferenceType = initialSources
            ?.map(source => {
                if (isPDFReference(String(source.domainUrl))) {
                    return {
                        sourceId: source.id,
                        sourceName: getReference(source),
                        feature: featureType,
                    };
                }
                return null; // Return null if condition is false
            })
            .filter(Boolean); // Filter out null or undefined values

        setPdfHighlightedReferences(highlightedReferenceType);
    }, []);

    return (
        <div className="flex flex-col items-start gap-3">
            <p className="text-lg font-bold">📚 References</p>
            <div className="flex flex-col items-start gap-3">
                {initialSources.map((source, index) => (
                    <ReferenceCard
                        key={index}
                        id={index + 1}
                        source={source}
                        featureType={featureType}
                        pdfHighlightedReferences={pdfHighlightedReferences}
                    />
                ))}
            </div>
        </div>
    );
};

export { References };
