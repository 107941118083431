import { Button } from '@kaya/kaya-ui-design-system-pb';
import { memo, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeStringify from 'rehype-stringify';
import remarkGfm from 'remark-gfm';
import { FeatureType } from '../../../__generated__/graphql';
import { CSS_SCROLL } from '../../../constants';
import { useChatContext } from '../../../contexts';
import { useHTMLTableToCsv } from '../../../hooks';
import { CN, gerCurrentDate } from '../../../utils';
import { ErrorBoundary } from 'react-error-boundary';
import Mermaid from '../mermaid/Mermaid.tsx';

const YouTubeEmbed = ({ url }: { url: string }) => {
    const { fullscreen } = useChatContext();
    const videoId = url.split('v=')[1];

    return (
        <div
            className={CN('relative overflow-hidden pb-[56.25%]', {
                'min-w-[480px] min-h-[360px] sm:min-w-max': fullscreen,
            })}
        >
            <iframe
                className="py-2 absolute inset-0 w-full h-full"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </div>
    );
};

const linkRender = ({ href, children }: any) => {
    // Check if the link is a YouTube video link
    if (href?.startsWith('https://www.youtube.com/watch')) {
        return <YouTubeEmbed url={href} />;
    }

    // Default behavior for other links
    return (
        <a target="_blank" href={href}>
            {children}
        </a>
    );
};

const tableRender = (props: any) => {
    const { featureType } = useChatContext();
    const tableRef = useRef<HTMLTableElement>(null);
    const { convertTableToCsv } = useHTMLTableToCsv();

    return (
        <div className="w-fit">
            <div className="w-full overflow-x-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                <table ref={tableRef} {...props}>
                    {props.children}
                </table>
            </div>
            {featureType == FeatureType?.KayaKbSqlAgent && (
                <div className="w-full flex justify-end mt-[8px]">
                    <Button
                        onClick={() =>
                            convertTableToCsv(tableRef?.current, `bernie-sql-table-export-${gerCurrentDate()}.csv`)
                        }
                        appearance="primary"
                        type="button"
                        view="outline"
                        size="small"
                    >
                        Download CSV
                    </Button>
                </div>
            )}
        </div>
    );
};

const handleCode = ({ node, inline, className, children, ...props }: any) => {
    const isMermaid = /language-mermaid/.test(className || '');
    return !inline && isMermaid ? (
        <Mermaid chart={children} />
    ) : (
        <code className={className} {...props}>
            {children}
        </code>
    );
};

const handlePre = ({ node, inline, className, children, ...props }: any) => {
    const childClassName = children[0]?.props?.className || '';

    return (
        <>
            {childClassName === 'language-mermaid' ? (
                <div>{children}</div>
            ) : (
                <pre className={className} {...props}>
                    {children}
                </pre>
            )}
        </>
    );
};

const Markdown = ({ markdownText }: { markdownText: string }) => {
    return (
        <ErrorBoundary
            fallback={
                <div className="flex flex-col gap-y-1">
                    <p>There was an issue with the original response!</p>
                    <p>Please try again later.</p>
                </div>
            }
        >
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw as any, rehypeStringify]}
                className={CN(
                    'prose prose-stone prose-sm dark:prose-invert markdown break-words !text-white',
                    CSS_SCROLL
                )}
                linkTarget="_blank"
                components={{
                    a: linkRender,
                    link: linkRender,
                    table: tableRender,
                    code: handleCode,
                    pre: handlePre,
                }}
                children={markdownText}
            />
        </ErrorBoundary>
    );
};

export const MemoizedMarkdown = memo(Markdown);
