import { Button, SearchInput, TextField } from '@kaya/kaya-ui-design-system-pb';
import { OperationType } from '../../../../../__generated__/graphql';
import { CUSTOM_META_DATA_KEYS, OPERATION_TYPES } from '../../../../../constants';
import { useAdminPanelContext } from '../../../../../contexts';
import { ExplanationTooltip } from '../explanation-tooltip';
import { useState, useEffect } from 'react';

export const MetaDataSection: React.FC = () => {
    const { kplFormData, setKPLFormData, selectedKPLAction } = useAdminPanelContext();

    // State to track which keys have been added
    const [addedKeys, setAddedKeys] = useState<string[]>([]); // Initialize with empty keys

    // Initialize addedKeys from kplFormData.criteria on component mount or when criteria changes
    useEffect(() => {
        const existingKeys = Object.keys(kplFormData.criteria);
        const notNullKeys = existingKeys.filter(key => kplFormData.criteria[key] !== null);
        setAddedKeys(notNullKeys.length ? notNullKeys : ['']); // If no existing keys, start with one empty key-value pair for adding
    }, [kplFormData.criteria]);

    // Check if the maximum limit of keys has been reached
    const limitReached = addedKeys.length === CUSTOM_META_DATA_KEYS.length;

    // Add a new empty criterion to the list
    const handleAddCriteria = () => {
        if (!limitReached) {
            const availableKey = CUSTOM_META_DATA_KEYS.find(key => !addedKeys.includes(key));
            if (availableKey) {
                setAddedKeys(prev => [...prev, availableKey]);
                // Set the new key to null, meaning it's being added (not yet confirmed by the user)
                setKPLFormData(prevState => ({
                    ...prevState,
                    criteria: {
                        ...prevState.criteria,
                        [availableKey]: '', // Set the value to null to indicate an unsaved/new entry
                    },
                }));
            }
        }
    };

    // Update the key in the addedKeys state and kplFormData.criteria
    const handleKeyChange = (index: number, newKey: string) => {
        const oldKey = addedKeys[index];

        // Ensure no duplicate keys
        if (!addedKeys.includes(newKey)) {
            setAddedKeys(prev => prev.map((k, i) => (i === index ? newKey : k)));

            // Update kplFormData.criteria by replacing the old key with the new key
            const { [oldKey]: oldValue, ...rest } = kplFormData.criteria;
            setKPLFormData(prevState => ({
                ...prevState,
                criteria: {
                    ...rest,
                    [newKey]: oldValue || '', // Keep the value under the new key, default to empty
                },
            }));
        }
    };

    // Update the value of a criterion in kplFormData.criteria
    const handleValueChange = (key: string, value: string) => {
        setKPLFormData(prevState => ({
            ...prevState,
            criteria: {
                ...prevState.criteria,
                [key]: value,
            },
        }));
    };

    // Remove a key-value pair from the criteria
    const handleRemoveCriteria = (index: number) => {
        const keyToRemove = addedKeys[index];
        setAddedKeys(prev => prev.filter((_, i) => i !== index));

        // Remove the key from kplFormData.criteria
        setKPLFormData(prevState => {
            const { [keyToRemove]: _, ...rest } = prevState.criteria;
            return { ...prevState, criteria: rest };
        });
    };

    // Handle operation type change
    const handleOperationChange = (value: OperationType) => {
        setKPLFormData(prevState => ({
            ...prevState,
            operation: value,
        }));
    };

    const getValue = (index: number): string => {
        handleKeyChange(index, CUSTOM_META_DATA_KEYS[0]);
        return CUSTOM_META_DATA_KEYS[0];
    };

    const getKeysWithValues = () => {
        const obj = kplFormData.criteria;
        const keysWithValues = Object.keys(obj).filter(
            key => obj[key as keyof typeof obj] != null && obj[key as keyof typeof obj] !== undefined
        );
        return keysWithValues.length;
    };

    return (
        <div className="meta-data-section border border-dashed rounded-sm border-B-400 flex flex-col gap-y-3 px-5 pt-3 pb-5">
            <p className="text-xs font-500 text-N-900 flex gap-x-1 pb-2">
                Meta Data Filtering Criteria <span className="text-R-700">*</span>
                <ExplanationTooltip
                    id="meta-data-filtering-criteria-tooltip"
                    description="Meta Data filtering criteria to filter the KPL data based on the provided criteria. Atleast one criteria is required."
                />
            </p>
            <div className="border-t border-t-N-200" />

            {/* Render only the added key-value pairs */}
            {addedKeys.map((key, index) => (
                <div key={index} className="flex gap-x-6 pb-4">
                    <div className="flex gap-x-10 w-full">
                        <div className="flex flex-col gap-y-2 w-full">
                            <p className="text-xs font-500 text-N-900 flex gap-x-1">
                                Key <span className="text-R-700">*</span>
                                <ExplanationTooltip
                                    id={`custom-key-tooltip-${index + 1}`}
                                    description="Predefined key for filtering criteria"
                                />
                            </p>
                            <SearchInput
                                wrapperClassName="w-full"
                                view="default"
                                placeholder={`Enter valid key (s)`}
                                items={CUSTOM_META_DATA_KEYS.filter(keyOption => !addedKeys.includes(keyOption))} // Filter out already used keys
                                value={key ? key : getValue(index)}
                                onKeyDown={e => e.preventDefault()}
                                onChange={(e: any) => handleKeyChange(index, e)}
                            />
                        </div>
                        <div className="flex flex-col gap-y-2 w-full relative">
                            <p className="text-xs font-500 text-N-900 flex gap-x-1">
                                Value <span className="text-R-700">*</span>
                                <ExplanationTooltip
                                    id={`value-tooltip-${index + 1}`}
                                    description="Provide the value of your choice. Add comma-separated values for multiple values."
                                />
                            </p>
                            <TextField
                                view="default"
                                placeholder={`Enter valid ${key ? key : 'value'} (s)`}
                                value={kplFormData.criteria[key] || ''}
                                onChange={(e: any) => handleValueChange(key, e.target.value)}
                            />
                            <div className="min-w-12 absolute top-[29px] left-[-31px]">
                                <i className="ri-arrow-right-fill text-h6 text-B-900" />
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center mt-5">
                        <Button
                            size="medium"
                            appearance={'primary'}
                            view="outline"
                            iconBefore={index === addedKeys.length - 1 ? 'ri-add-line' : 'ri-delete-bin-line'}
                            onClick={() => {
                                if (index === addedKeys.length - 1) {
                                    handleAddCriteria();
                                } else {
                                    handleRemoveCriteria(index);
                                }
                            }}
                        />
                    </div>
                </div>
            ))}

            <div className="border-t border-t-N-200" />
            <SearchInput
                wrapperClassName="w-full"
                view="default"
                label="Operation"
                items={OPERATION_TYPES}
                value={kplFormData.operation}
                onChange={(e: any) => handleOperationChange(e)}
                disabled={getKeysWithValues() < 2}
            />
        </div>
    );
};
