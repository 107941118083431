import { Button, SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import { useChatContext, useCustomKplContext } from '../../../contexts';
import { CommonMessage } from '../message';
import { useEffect, useState } from 'react';

export const ConfirmCreateKpl = () => {
    const {
        handleSave,
        isKplCreated,
        isLoading,
        name,
        errorText,
        navigateToKpl,
        isKplLoading,
        failedFileUploads,
        unreadableFiles,
    } = useCustomKplContext();
    const { refetchCurrentUserConfig, refetchApplicationConfigs } = useChatContext();
    const [isKplsLoading, setIsKplsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!isKplCreated) return;

        refetchCurrentUserConfig().finally(() => {
            refetchApplicationConfigs();
            setIsKplsLoading(false);
        });
    }, [isKplCreated]);

    return !isKplCreated ? (
        <div className="flex flex-col gap-y-3 w-fit">
            <p>👇 Please click the button below to proceed with KPL creation.</p>
            <Button
                className="bg-B-500 hover:bg-B-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                onClick={() => handleSave()}
                isLoading={isLoading}
                iconBefore={isLoading ? '' : 'ri-sparkling-line'}
            >
                Proceed with KPL creation
            </Button>
            {errorText !== '' && (
                <p className="text-R-600 text-xs">
                    <i>{errorText}</i>
                </p>
            )}
        </div>
    ) : (
        <CommonMessage
            text={''}
            content={
                isKplsLoading || isKplLoading ? (
                    <div className="max-w-[80%] sm:max-w-full flex items-center gap-x-3">
                        <SpinLoader />
                        <span>Preparing your KPL.</span>
                    </div>
                ) : (
                    <div className="max-w-[80%] sm:max-w-full">
                        <p>{`Perfect! Your ${name} has been successfully created.`}</p>
                        <span>
                            By navigating to the Explore KPLs -{'>'}
                            <span className="mx-1">{name}</span>
                            you can start a conversation with your own KPL.
                            <br />
                            Or by clicking on this Link
                            <span className="text-B-700 mx-1 cursor-pointer" onClick={() => navigateToKpl()}>
                                Navigate to the custom KPL
                            </span>
                            you can navigate to the KPL as well.
                        </span>

                        <div className="mt-1">
                            {unreadableFiles.length > 0 && (
                                <div className="bg-red-100 py-4 rounded-lg mb-4">
                                    <p className="mt-2 text-red-700 text-sm">
                                        However, we have identified some unreadable files among those you attempted to
                                        ingest. The following files were not ingested into the KPL. Please check the
                                        file formats and contents, then try uploading them again through Custom KPL -
                                        {'>'} Configure to ensure proper processing. You can still use the KPL with the
                                        files that were successfully ingested.
                                    </p>
                                    <p className="text-red-700 font-semibold my-2">Unreadable Files Detected</p>
                                    <ul className="list-disc list-inside mt-2">
                                        {unreadableFiles.map((file, index) => (
                                            <li key={index} className="text-sm text-red-600">
                                                <i className="fas fa-exclamation-circle mr-2 text-red-500"></i>
                                                <i className="text-xs text-R-600">{file}</i>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {failedFileUploads.length > 0 && (
                                <div className="bg-yellow-100 py-4 rounded-lg">
                                    <p className="mt-2 text-red-700 text-sm">
                                        However, it seems, some of your files didn't upload successfully. You still have
                                        the ability to start a conversation using the documents which were successfully
                                        uploaded. You can easily try re-uploading the failed documents by navigating to
                                        Custom KPL -{'>'} Configure.
                                    </p>
                                    <p className="text-yellow-700 font-semibold">Failed File Uploads</p>
                                    <ul className="list-disc list-inside my-2">
                                        {failedFileUploads.map((file, index) => (
                                            <li key={index} className="text-sm text-yellow-600">
                                                <i className="fas fa-upload mr-2 text-yellow-500"></i>
                                                <i className="text-xs text-R-600">{file}</i>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        />
    );
};
