import { createContext, ReactNode, useContext, useState } from 'react';
import { useMobile } from '../hooks';

export enum SideBarButtonSelectionTypes {
    ChatHistorySelection = 'CHAT_HISTORY_SELECTION',
    KplListSelection = 'KPL_LIST_SELECTION',
    SettingSelection = 'SETTING_SELECTION',
    AdminPanelSelection = 'ADMIN_PANEL_SELECTION',
    CustomKplSection = 'CUSTOM_KPL_SECTION',
}

export enum AdminPanelSectionSelectionTypes {
    NOT_SELECTED = 'No Selection',
    KPL_AUTHORING = 'KPL Authoring',
    USER_AUTHORING = 'User Authoring',
    EXTERNAL_USER = 'External User',
    PROMPT_AUTHORING = 'Prompt Authoring',
    CUSTOM_KPL = 'Custom KPL',
}

export enum CustomKplSectionSelectionTypes {
    CREATE = 'Create',
    CONFIGURE = 'Configure',
}

export enum CustomKplSectionCreationSteps {
    CREATE_NAME = 'Create Name',
    RESPONSE_CONFIGURE = 'Response Configure',
    UPLOAD_DOCUMENTS = 'Upload Documents',
    CONFIRM_PROCESSED = 'Confirm Processed',
    KPL_CREATED = 'Kpl Created',
}

interface ISideBarContext {
    sideBarButtonSelection: SideBarButtonSelectionTypes;
    setSideBarButtonSelection: React.Dispatch<React.SetStateAction<SideBarButtonSelectionTypes>>;
    isCollapsed: boolean;
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    showMobileSideBar: boolean;
    setShowMobileSideBar: React.Dispatch<React.SetStateAction<boolean>>;
    handleMobileMenuIconClick: Function;
    showFeedbackModal: boolean;
    setShowFeedbackModal: React.Dispatch<React.SetStateAction<boolean>>;
    adminPanelSelection: AdminPanelSectionSelectionTypes;
    setAdminPanelSelection: React.Dispatch<React.SetStateAction<AdminPanelSectionSelectionTypes>>;
    setIsMenuCollapsed: Function;
    isMenuCollapsed: Boolean;
    setCustomKplSelection: Function;
    customKplSelection: CustomKplSectionSelectionTypes;
}

interface ISideBarContextProvider {
    children: ReactNode;
}

const SideBarContext = createContext<ISideBarContext>({
    sideBarButtonSelection: SideBarButtonSelectionTypes.ChatHistorySelection,
    setSideBarButtonSelection: () => {},
    isCollapsed: false,
    setIsCollapsed: () => {},
    showMobileSideBar: false,
    setShowMobileSideBar: () => {},
    handleMobileMenuIconClick: () => {},
    showFeedbackModal: false,
    setShowFeedbackModal: () => {},
    adminPanelSelection: AdminPanelSectionSelectionTypes.NOT_SELECTED,
    setAdminPanelSelection: () => {},
    setIsMenuCollapsed: () => {},
    isMenuCollapsed: true,
    setCustomKplSelection: () => {},
    customKplSelection: CustomKplSectionSelectionTypes.CREATE,
});

export const SideBarContextProvider: React.FC<ISideBarContextProvider> = ({ children }) => {
    const isMobile = useMobile();
    const [sideBarButtonSelection, setSideBarButtonSelection] = useState<SideBarButtonSelectionTypes>(
        SideBarButtonSelectionTypes.ChatHistorySelection
    );
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [showMobileSideBar, setShowMobileSideBar] = useState<boolean>(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
    const [adminPanelSelection, setAdminPanelSelection] = useState<AdminPanelSectionSelectionTypes>(
        AdminPanelSectionSelectionTypes.NOT_SELECTED
    );
    const [customKplSelection, setCustomKplSelection] = useState<CustomKplSectionSelectionTypes>(
        CustomKplSectionSelectionTypes.CREATE
    );
    const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(true);

    const handleMobileMenuIconClick = () => {
        setShowMobileSideBar(!showMobileSideBar);
        setIsCollapsed(showMobileSideBar ? false : isCollapsed);
    };

    return (
        <SideBarContext.Provider
            value={{
                sideBarButtonSelection,
                setSideBarButtonSelection,
                isCollapsed,
                setIsCollapsed,
                showMobileSideBar,
                setShowMobileSideBar,
                handleMobileMenuIconClick,
                showFeedbackModal,
                setShowFeedbackModal,
                adminPanelSelection,
                setAdminPanelSelection,
                isMenuCollapsed,
                setIsMenuCollapsed,
                setCustomKplSelection,
                customKplSelection,
            }}
        >
            {children}
        </SideBarContext.Provider>
    );
};

export const useSideBarContext = (): ISideBarContext => {
    const context = useContext(SideBarContext);

    if (!context) {
        throw new Error('useChatContext must be used within a ChatContextProvider');
    }

    return context;
};
