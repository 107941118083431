import { useEffect, useState } from 'react';
import { DocumentsUpload } from '../../../../chat-with-documents/DocumentsUpload';
import { KnowledgeDocument } from '../../custom-kpl-configure/common/knowledge-document/KnowledgeDocument';
import { ACCEPTED_FILE_LIST_IN_CUSTOM_KPL } from '../../../../../../constants';

type IkplFileUploader = {
    files: File[];
    handleUpload: Function;
    isFileUploaded: boolean;
    hasFileLimit?: boolean;
    hasFileSizeLimit?: boolean;
    maxFileSize?: number;
    maxFileCount?: number;
    showDocumentToggles?: boolean;
    acceptedFiles?: string;
};

export const KplFileUploader = ({
    files,
    handleUpload,
    isFileUploaded,
    hasFileLimit = false,
    hasFileSizeLimit = false,
    maxFileSize = 100,
    maxFileCount = 200,
    showDocumentToggles = true,
    acceptedFiles = ACCEPTED_FILE_LIST_IN_CUSTOM_KPL,
}: IkplFileUploader) => {
    const [warningMessage, setWarningMessage] = useState<string>('');
    const [isFileLimitationValid, setIsFileLimitationValid] = useState<boolean>(true);

    useEffect(() => {
        const totalSize = files.reduce((acc, file) => acc + file.size, 0);

        if (hasFileSizeLimit && totalSize > maxFileSize * 1024 * 1024) {
            setWarningMessage(`Total file size exceeds the maximum limit of ${maxFileSize} MB.`);
            setIsFileLimitationValid(false);
        } else if (hasFileLimit && files.length > maxFileCount) {
            setWarningMessage(`You can only upload a maximum of ${maxFileCount} files.`);
            setIsFileLimitationValid(false);
        } else {
            setWarningMessage('');
            setIsFileLimitationValid(true);
        }
    }, [files]);

    return isFileUploaded ? (
        <div className="w-full flex flex-col gap-y-2 duration-300 animate-fade-up animate-once">
            {files.map((file, i) => (
                <KnowledgeDocument name={file?.name} key={i} fileSize={file?.size} isHideClose={true} />
            ))}
        </div>
    ) : (
        <>
            <DocumentsUpload
                onClickUpload={handleUpload}
                isFileLimitationValid={isFileLimitationValid}
                warningMessage={warningMessage}
                showDocumentToggles={showDocumentToggles}
                initialLabel="Ingest to KPL"
                postLabel="Documents ingested"
                acceptedFiles={acceptedFiles}
            />
        </>
    );
};
