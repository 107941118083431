import { useLazyQuery } from '@apollo/client';
import { CheckBox, SearchInput, TextField } from '@kaya/kaya-ui-design-system-pb';
import { uniqueId } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { ApplicationUserType, UserType } from '../../../__generated__/graphql';
import { ALL_VALUE_KPL, findModelName, MODEL_TYPES } from '../../../constants';
import { useAdminPanelContext, UserFormData, UserMetadata } from '../../../contexts';
import { GET_USER_BY_EMAIL } from '../../../graphql';
import { useAllKPLs, useMobile } from '../../../hooks';
import { KPLMultiSelect, ModelMultiSelect } from '../custom-select-components';
import { ExplanationTooltip } from '../KPL-management-container/common/explanation-tooltip';
import { Chip } from './Chip';

interface IUserFormProps {
    selectedEmail?: string;
    isEdit?: boolean;
}

export const UserForm: React.FC<IUserFormProps> = ({ selectedEmail, isEdit = false }) => {
    const [getSingleUserData] = useLazyQuery(GET_USER_BY_EMAIL, { fetchPolicy: 'network-only' });
    const { userFormData, setUserFormData } = useAdminPanelContext();
    const { ALL_KPL_SELECT_LIST, KPL_SELECT_LIST, findTitleByConfigKey } = useAllKPLs();
    const isMobile = useMobile();

    useEffect(() => {
        const fetchUser = async () => {
            if (selectedEmail) {
                const { data } = await getSingleUserData({ variables: { email: selectedEmail } });
                const user = data?.getUserByUserName;
                if (user) {
                    const initialData: UserFormData = {
                        firstName: user.firstName || '',
                        lastName: user.lastName || '',
                        email: user.email || '',
                        override: user.override || false,
                        password: '',
                        isTemporaryPass: false,
                        models: user.models || [],
                        features: user.features || [],
                        metadata: {
                            default: {
                                model: user.metadata.default.model || '',
                                feature: user.metadata.default.feature || '',
                                webSearch: user.metadata.default.webSearch || false,
                                dynamicPrompts: user.metadata.default.dynamicPrompts || false,
                                noLLM: user.metadata.default.noLLM || false,
                            },
                            userType: user.metadata.userType || '',
                        },
                        isGuestUser: user.userType === ApplicationUserType.BernieGuestUser,
                    };
                    setUserFormData(initialData);
                }
            }
        };
        if (isEdit) fetchUser();
    }, [selectedEmail, isEdit]);

    const handleInputChange = (field: keyof UserFormData, value: any) => {
        setUserFormData(prev => ({ ...prev, [field]: value }));
    };

    const toggleSettingOption = (optionKey: keyof UserMetadata['default']) => {
        setUserFormData(prev => ({
            ...prev,
            metadata: {
                ...prev.metadata,
                default: {
                    ...prev.metadata.default,
                    [optionKey]: !prev.metadata.default[optionKey],
                },
            },
        }));
    };

    const handleModelSelection = (value: any) => {
        setUserFormData(prev => {
            const key = value.id;
            const isRemoving = prev.models.includes(key);

            const updatedModels = isRemoving ? prev.models.filter((id: string) => id !== key) : [...prev.models, key];

            const updatedMetadata =
                isRemoving && prev.metadata.default.model === key
                    ? { ...prev.metadata, default: { ...prev.metadata.default, model: '' } }
                    : prev.metadata;

            return {
                ...prev,
                models: updatedModels,
                metadata: updatedMetadata,
            };
        });
    };

    const handleKplSelection = (value: any) => {
        setUserFormData(prev => {
            const key = value.id;
            const isRemoving = prev.features.includes(key);

            const updatedFeatures = isRemoving
                ? prev.features.filter((id: string) => id !== key)
                : [...prev.features, key];

            const updatedMetadata =
                isRemoving && prev.metadata.default.feature === key
                    ? { ...prev.metadata, default: { ...prev.metadata.default, feature: '' } }
                    : prev.metadata;

            return {
                ...prev,
                features: updatedFeatures,
                metadata: updatedMetadata,
            };
        });
    };

    const setEmptyDefaultKPL = () => {
        setUserFormData(prev => ({
            ...prev,
            metadata: {
                ...prev.metadata,
                default: {
                    ...prev.metadata.default,
                    feature: '',
                },
            },
        }));
    };

    const setEmptyDefaultModel = () => {
        setUserFormData(prev => ({
            ...prev,
            metadata: {
                ...prev.metadata,
                default: {
                    ...prev.metadata.default,
                    model: '',
                },
            },
        }));
    };

    const handleSelectDefaultKPL = (kplKey: any) => {
        handleInputChange('metadata', {
            ...userFormData.metadata,
            default: {
                ...userFormData.metadata.default,
                feature: kplKey,
            },
        });
    };

    const handleSelectDefaultModel = (modelType: any) => {
        handleInputChange('metadata', {
            ...userFormData.metadata,
            default: {
                ...userFormData.metadata.default,
                model: modelType,
            },
        });
    };

    const ALL_KPLS = ALL_KPL_SELECT_LIST.map((kpl, index) => ({
        id: index + 1,
        key: kpl.configKey,
        isDefault: false,
        isSelected: true,
    }));

    const SELECTED_KPLS = userFormData.features.map((feature, index) => ({
        id: index + 1,
        key: feature,
        isDefault: false,
        isSelected: true,
    }));

    const SELECTABLE_MODELS = MODEL_TYPES.map(modelType => modelType?.model);

    const models = userFormData.models
        .map((model, index) => ({
            id: index + 1,
            key: model,
            isDefault: false,
            isSelected: true,
        }))
        .toSorted((a, b) => findModelName(a.key).localeCompare(findModelName(b.key)));

    const hasAllKPLs = useMemo(() => {
        return userFormData.features.includes(ALL_VALUE_KPL);
    }, [userFormData]);

    const hasAllModels = useMemo(() => {
        return userFormData.models.length === SELECTABLE_MODELS.length;
    }, [userFormData]);

    const kplListShow = hasAllKPLs ? ALL_KPLS : SELECTED_KPLS;
    const kpls = kplListShow?.toSorted((a, b) =>
        findTitleByConfigKey(a.key).localeCompare(findTitleByConfigKey(b.key))
    );

    return (
        <div className="flex flex-col gap-y-6 p-2 mb-[10vh]">
            {!isEdit && (
                <div className="flex flex-col gap-y-1 w-full">
                    <span className="text-xs font-500 text-N-900">User Type</span>
                    <SearchInput
                        className="w-full"
                        placeholder="Search User Type"
                        view="default"
                        value={userFormData.metadata.userType || UserType.User}
                        items={[UserType.Domain, UserType.User]}
                        onChange={(e: any) => {
                            setUserFormData(prev => ({
                                ...prev,
                                metadata: {
                                    ...prev.metadata,
                                    userType: e,
                                },
                            }));
                        }}
                    />
                </div>
            )}

            <div className="flex flex-col gap-y-5">
                <div className="flex items-center gap-x-5">
                    <TextField
                        id="email"
                        type="email"
                        view="default"
                        label={userFormData.metadata.userType === UserType.User ? 'Email address' : 'Domain address'}
                        required
                        disabled={isEdit}
                        placeholder={
                            userFormData.metadata.userType === UserType.User
                                ? 'Enter email address'
                                : 'Enter domain address'
                        }
                        wrapperClassName="w-full"
                        autoComplete="nope"
                        value={userFormData.email || ''}
                        onChange={(e: any) => {
                            handleInputChange('email', e.target.value);
                        }}
                    />
                    {(!isEdit || userFormData.isPasswordUpdate) &&
                        userFormData.metadata.userType !== UserType.Domain && (
                            <TextField
                                id="password"
                                view="default"
                                label="Password"
                                required
                                placeholder="Enter password"
                                wrapperClassName="w-full"
                                autoComplete="nope"
                                onChange={(e: any) => {
                                    handleInputChange('password', e.target.value);
                                }}
                            />
                        )}
                </div>

                {userFormData.metadata.userType !== UserType.Domain && (
                    <div className="flex items-center gap-x-5">
                        <TextField
                            id="first_name"
                            view="default"
                            label="First name"
                            required
                            placeholder="Enter First name"
                            wrapperClassName="w-full"
                            autoComplete="nope"
                            value={userFormData.firstName || ''}
                            onChange={(e: any) => handleInputChange('firstName', e.target.value)}
                        />
                        <TextField
                            id="last_name"
                            view="default"
                            label="Last name"
                            required
                            placeholder="Enter Last name"
                            wrapperClassName="w-full"
                            autoComplete="nope"
                            value={userFormData.lastName || ''}
                            onChange={(e: any) => handleInputChange('lastName', e.target.value)}
                        />
                    </div>
                )}

                {isEdit && userFormData.metadata.userType !== UserType.Domain && (
                    <div className="flex items-center gap-x-5">
                        <CheckBox
                            id={uniqueId()}
                            checked={userFormData.isPasswordUpdate}
                            onChange={(e: any) => handleInputChange('isPasswordUpdate', e.target.checked)}
                            label="Reset password"
                        />
                    </div>
                )}

                {(userFormData.isPasswordUpdate || !isEdit) && userFormData.metadata.userType !== UserType.Domain && (
                    <div className="flex items-center gap-x-5">
                        <CheckBox
                            id={uniqueId()}
                            checked={userFormData.isTemporaryPass}
                            onChange={(e: any) => handleInputChange('isTemporaryPass', e.target.checked)}
                            label="Temporary Password"
                        />
                    </div>
                )}

                {userFormData.metadata.userType !== UserType.Domain && (
                    <div className="flex items-center gap-x-5">
                        <CheckBox
                            label="Override Domain"
                            id={uniqueId()}
                            checked={userFormData.override}
                            onChange={(e: any) => handleInputChange('override', e.target.checked)}
                        />
                    </div>
                )}

                <div className="border-t border-t-N-200" />
                <div className="flex items-center gap-x-2">
                    <p className="text-N-900 font-500 text-sm w-fit whitespace-nowrap">Enable / Disable KPLs</p>
                    <div className="w-full h-[1px] mt-[2px] bg-[#E5E7EB]" />
                </div>
                <div>
                    <CheckBox
                        id={uniqueId()}
                        label="Enable All KPLs"
                        checked={hasAllKPLs}
                        onChange={(e: any) => {
                            handleInputChange('features', e.target.checked ? [ALL_VALUE_KPL] : []);
                            if (!e.target.checked) {
                                setEmptyDefaultKPL();
                            }
                        }}
                    />
                    {!hasAllKPLs && (
                        <KPLMultiSelect
                            taglength={isMobile ? 3 : 5}
                            width="w-full my-2"
                            selectedKpls={hasAllKPLs ? [] : kpls.map(kpl => kpl.key)}
                            onKPLChange={handleKplSelection}
                        />
                    )}
                    <div className="flex flex-wrap gap-x-[8px] gap-y-[12px] max-h-[150px] my-2 overflow-y-scroll">
                        {kpls.map((kpl, index) => (
                            <div key={index}>
                                <Chip
                                    isMarked={kpl.key === userFormData.metadata.default.feature}
                                    isClosable={false}
                                    isSelfSeletable={true}
                                    isSelected={kpl.isSelected}
                                    label={findTitleByConfigKey(kpl.key)}
                                    onAction={() => handleSelectDefaultKPL(kpl.key)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex items-center gap-x-2">
                    <p className="text-N-900 font-500 text-sm w-fit whitespace-nowrap">Enable / Disable Models</p>
                    <div className="w-full h-[1px] mt-[2px] bg-[#E5E7EB]" />
                </div>
                <div>
                    <CheckBox
                        id={uniqueId()}
                        label="Enable All Models"
                        checked={hasAllModels}
                        className="mb-2"
                        onChange={(e: any) => {
                            handleInputChange('models', e.target.checked ? SELECTABLE_MODELS : []);
                            if (!e.target.checked) {
                                setEmptyDefaultModel();
                            }
                        }}
                    />

                    {!hasAllModels && (
                        <ModelMultiSelect
                            taglength={isMobile ? 3 : 5}
                            width="w-full"
                            selectedModels={models.map(model => model.key)}
                            onChange={handleModelSelection}
                        />
                    )}
                    <div className="flex flex-wrap gap-x-[8px] gap-y-[12px] max-h-[150px] my-2 overflow-y-scroll">
                        {models?.map((model, index) => (
                            <div key={index}>
                                <Chip
                                    isMarked={model.key === userFormData.metadata.default.model}
                                    isClosable={false}
                                    isSelfSeletable={true}
                                    isSelected={model.isSelected}
                                    label={findModelName(model.key)}
                                    onAction={() => handleSelectDefaultModel(model.key)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-y-5">
                <p className="text-xs font-500 text-N-900 flex gap-x-1">
                    Enable Additional Settings
                    <ExplanationTooltip id="additional-settings-tooltip" description="Advanced KPL settings." />
                </p>

                <div className="flex flex-col gap-y-2">
                    <CheckBox
                        checked={userFormData.metadata.default.webSearch}
                        onChange={() => toggleSettingOption('webSearch')}
                        label="Web Search"
                        id={uniqueId()}
                    />
                    <CheckBox
                        checked={userFormData.metadata.default.dynamicPrompts}
                        onChange={() => toggleSettingOption('dynamicPrompts')}
                        label="Dynamic Prompt"
                        id={uniqueId()}
                    />
                    <CheckBox
                        checked={userFormData.metadata.default.noLLM}
                        onChange={() => toggleSettingOption('noLLM')}
                        label="NoLLM"
                        id={uniqueId()}
                    />
                </div>
            </div>
        </div>
    );
};
