import { useMutation, useSubscription } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { AddMessageMutation, MessageAuthor, MessageType } from '../__generated__/graphql';
import { SYSTEM_TEMP_ID, TOTAL_TEMP_MESSAGE_TIME, USER_TEMP_ID } from '../constants';
import { IMessage, useChatContext, useCustomKplContext } from '../contexts';
import { ADD_MESSAGE, CHAT_SUBSCRIPTION } from '../graphql';
import { clearCustomization, logger } from '../utils';

const tempMessages = [
    {
        content: `We're on it! Your results are coming up soon.`,
        author: MessageAuthor.System,
        timer: 10000,
    },
    {
        content: `Sit tight! Your results will be with you in just a moment.`,
        author: MessageAuthor.System,
        timer: 25000,
    },
    {
        content: 'Just a little more time, and your results will be ready to go.',
        author: MessageAuthor.System,
        timer: 60000,
    },
];

const addTemporarySystemMessages = (
    setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>,
    addedMessage?: AddMessageMutation | null
) => {
    const timers = tempMessages.map(tempMessage =>
        setTimeout(() => {
            setMessages(prevMessages => {
                const lastMessageId = prevMessages.slice(-1)[0]?.id;

                if (lastMessageId !== String(addedMessage?.addMessage?.id) && lastMessageId !== SYSTEM_TEMP_ID) {
                    return prevMessages;
                }

                const updatedMessages = prevMessages.map(message => {
                    if (message.id === SYSTEM_TEMP_ID) {
                        return { ...message, ...tempMessage };
                    }
                    return message;
                });

                const messageExists = updatedMessages.some(message => message.id === SYSTEM_TEMP_ID);

                if (!messageExists) {
                    updatedMessages.push({ id: SYSTEM_TEMP_ID, ...tempMessage });
                }

                return updatedMessages;
            });
        }, tempMessage.timer)
    );

    const finalTimer = setTimeout(() => {
        logger.debug('Loading set to false!');
        setMessages(prevMessages =>
            prevMessages.filter(newMsg => newMsg.id !== USER_TEMP_ID && newMsg.id !== SYSTEM_TEMP_ID)
        );
        timers.forEach(timer => clearTimeout(timer));
    }, TOTAL_TEMP_MESSAGE_TIME);

    return () => clearTimeout(finalTimer);
};

const useMessenger = () => {
    const { userDetails, conversationId, settings, setConversationId, setMessages, setLoading, isCurrentUserGuest } =
        useChatContext();
    const { fetchHeaderMetaData } = useCustomKplContext();

    const { data, loading } = useSubscription(CHAT_SUBSCRIPTION, {
        variables: {
            input: {
                conversationId,
                sessionId: userDetails.sessionId,
                userId: userDetails.id,
            },
        },
    });

    const [addMessage, { data: addedMessage }] = useMutation(ADD_MESSAGE);

    useEffect(() => {
        if (!conversationId) {
            const newConversationId = crypto.randomUUID();
            setConversationId(newConversationId);
            logger.log('New conversation id is set: ' + newConversationId);
        }

        setMessages([]); // Reset messages when conversationId changes
    }, [conversationId, setConversationId, setMessages]);

    useEffect(() => {
        setLoading(loading);
    }, [loading, setLoading]);

    useEffect(() => {
        if (!data || !data.newMessage || data.newMessage.length === 0) {
            logger.debug('No messages!');
            return;
        }

        setMessages(prevMessages => {
            const messageMap = new Map(prevMessages.map(message => [message.id, message]));

            data?.newMessage?.forEach(newMsg => {
                const messageId = String(newMsg?.id);
                const existingMsg = messageMap.get(messageId);
                if (existingMsg && existingMsg.content && existingMsg.processing) {
                    const isEmpty = Boolean(newMsg?.content?.trim() === '');

                    existingMsg.content = isEmpty ? existingMsg.content : String(newMsg?.content);
                    existingMsg.processing = isEmpty ? false : Boolean(newMsg?.processing);
                    existingMsg.sources =
                        newMsg?.sources?.map(source => ({
                            id: source?.id || '',
                            domainUrl: source?.domainUrl || '',
                            sources: source?.sources || [],
                        })) || [];
                } else {
                    messageMap.set(messageId, newMsg as IMessage);
                }
            });

            const updatedMessages = [...messageMap.values()].filter(
                newMsg => newMsg.id !== USER_TEMP_ID && newMsg.id !== SYSTEM_TEMP_ID
            );

            setLoading(updatedMessages.slice(-1)[0]?.id === String(addedMessage?.addMessage?.id));

            if (isCurrentUserGuest) {
                fetchHeaderMetaData();
            }

            return updatedMessages;
        });

        return addTemporarySystemMessages(setMessages, addedMessage);
    }, [addedMessage, data, setLoading, setMessages]);

    const submitMessage = useCallback(
        async (content: string) => {
            try {
                if (content) {
                    setLoading(true);
                    const tempMessage = { id: USER_TEMP_ID, content, author: MessageAuthor.User };
                    setMessages(prevMessages => [...prevMessages, tempMessage]);
                    await addMessage({
                        variables: {
                            input: {
                                author: MessageAuthor.User,
                                type: MessageType.Text,
                                content,
                                conversationId,
                                sessionId: userDetails.sessionId,
                                userId: userDetails.id,
                                settings,
                                isGuestUser: isCurrentUserGuest,
                            },
                        },
                    });
                    clearCustomization(userDetails.email, content);
                }
            } catch (error) {
                logger.error(error);
                setLoading(false);
            }
        },
        [addMessage, settings, conversationId, setLoading, setMessages, userDetails, isCurrentUserGuest]
    );

    return { submitMessage };
};

export { useMessenger };
